/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const authorizations = /* GraphQL */ `query Authorizations(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  authorizations(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      amount
      authorization_date
      authorization_id
      captured_amount
      currency
      device_id
      expiration_date
      failure_reasons
      fee_mode
      fees
      merchant_uid
      metadata
      reference
      sale_id
      status
      timezone
      transaction_id
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AuthorizationsQueryVariables,
  APITypes.AuthorizationsQuery
>;
export const backOfficeKey = /* GraphQL */ `query BackOfficeKey(
  $merchant_uid: String!
  $entity_level: BackofficeKeyEntityLevel!
) {
  backOfficeKey(merchant_uid: $merchant_uid, entity_level: $entity_level) {
    created_date
    enabled
    entity_level
    key_name
    last_accessed_date
    merchant_uid
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BackOfficeKeyQueryVariables,
  APITypes.BackOfficeKeyQuery
>;
export const defaultPaymentParameters = /* GraphQL */ `query DefaultPaymentParameters {
  defaultPaymentParameters {
    denied_after_date
    denied_before_date
    enabled
    expires_in
    maximum_amount
    maximum_occurrence
    minimum_amount
    payment_parameters_name
    payment_parameters_type
    recurrence_period
    validation_link_url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DefaultPaymentParametersQueryVariables,
  APITypes.DefaultPaymentParametersQuery
>;
export const devices = /* GraphQL */ `query Devices(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  devices(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      device_id
      device_description
      device_name
      device_properties
      is_active
      merchant_uid
      processor
      processor_device_id
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.DevicesQueryVariables, APITypes.DevicesQuery>;
export const disputeEvidence = /* GraphQL */ `query DisputeEvidence($dispute_id: String!, $merchant_uid: ID) {
  disputeEvidence(dispute_id: $dispute_id, merchant_uid: $merchant_uid) {
    dispute_id
    evidence_date
    filename
    merchant_uid
    status
    updated_date
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DisputeEvidenceQueryVariables,
  APITypes.DisputeEvidenceQuery
>;
export const disputeEvidenceTokens = /* GraphQL */ `query DisputeEvidenceTokens(
  $action: AwsS3Action!
  $dispute_id: String!
  $filenames: [String]!
  $mime_types: [String!]
  $merchant_uid: ID
) {
  disputeEvidenceTokens(
    action: $action
    dispute_id: $dispute_id
    filenames: $filenames
    mime_types: $mime_types
    merchant_uid: $merchant_uid
  )
}
` as GeneratedQuery<
  APITypes.DisputeEvidenceTokensQueryVariables,
  APITypes.DisputeEvidenceTokensQuery
>;
export const disputes = /* GraphQL */ `query Disputes(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  disputes(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      amount
      dispute_date
      dispute_id
      evidence_last_send_date
      expiration_date
      merchant_uid
      status
      reason
      reason_message
      settlement_deposit_batch
      settlement_withdrawal_batch
      updated_date
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.DisputesQueryVariables, APITypes.DisputesQuery>;
export const errorText = /* GraphQL */ `query ErrorText($language_code: String!, $error_codes: [String]!) {
  errorText(language_code: $language_code, error_codes: $error_codes)
}
` as GeneratedQuery<APITypes.ErrorTextQueryVariables, APITypes.ErrorTextQuery>;
export const generateCSV = /* GraphQL */ `query GenerateCSV($csvInput: CSVInput!) {
  generateCSV(csvInput: $csvInput) {
    bucket_name
    filepaths
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GenerateCSVQueryVariables,
  APITypes.GenerateCSVQuery
>;
export const hostedCheckoutPage = /* GraphQL */ `query HostedCheckoutPage($session_id: String!) {
  hostedCheckoutPage(session_id: $session_id) {
    accepted_payment_methods
    account_code
    amount
    api_key
    call_to_action
    checkout_type
    currency
    fee_mode
    invoice_id
    is_active
    merchant_name
    merchant_uid
    metadata
    origin
    payment_description
    payment_name
    payment_parameters
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    recurring_id
    require_phone
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HostedCheckoutPageQueryVariables,
  APITypes.HostedCheckoutPageQuery
>;
export const invoiceCheckoutPage = /* GraphQL */ `query InvoiceCheckoutPage($invoice_id: String!) {
  invoiceCheckoutPage(invoice_id: $invoice_id) {
    account_code
    api_key
    created_date
    currency
    due_by
    fee_mode
    invoice_amount
    invoice_date
    invoice_description
    invoice_id
    invoice_name
    merchant_invoice_number
    merchant_name
    merchant_uid
    metadata
    offline_transactions {
      amount
      instance_id
      invoice_id
      note
      transaction_date
      type
      __typename
    }
    payor {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    reference
    settings {
      is_secure
      require_payor_address
      security_pin
      __typename
    }
    status
    total_paid_amount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceCheckoutPageQueryVariables,
  APITypes.InvoiceCheckoutPageQuery
>;
export const invoices = /* GraphQL */ `query Invoices(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  invoices(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      api_key
      created_date
      currency
      due_by
      fee_mode
      invoice_amount
      invoice_date
      invoice_description
      invoice_id
      invoice_name
      merchant_invoice_number
      merchant_name
      merchant_uid
      metadata
      reference
      status
      total_paid_amount
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.InvoicesQueryVariables, APITypes.InvoicesQuery>;
export const merchant = /* GraphQL */ `query Merchant($merchant_uid: String, $merchant_name: String) {
  merchant(merchant_uid: $merchant_uid, merchant_name: $merchant_name) {
    ach_active
    card_active
    cash_active
    is_system
    merchant_name
    merchant_uid
    parent_merchant_uid
    submitted_onboarding
    __typename
  }
}
` as GeneratedQuery<APITypes.MerchantQueryVariables, APITypes.MerchantQuery>;
export const merchantOnboarding = /* GraphQL */ `query MerchantOnboarding($merchant_uid: ID!) {
  merchantOnboarding(merchant_uid: $merchant_uid) {
    bank {
      account_number
      account_type
      bank_code
      name
      __typename
    }
    business {
      __typename
    }
    business_owners {
      dob
      email
      first_name
      last_name
      middle_initial
      percentage_ownership
      phone
      primary_owner
      tax_id
      title
      uid
      __typename
    }
    is_locked
    merchant_uid
    needs_docs
    ticket_id
    user_email
    user_full_name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MerchantOnboardingQueryVariables,
  APITypes.MerchantOnboardingQuery
>;
export const merchants = /* GraphQL */ `query Merchants(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  merchants(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      ach_active
      card_active
      cash_active
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.MerchantsQueryVariables, APITypes.MerchantsQuery>;
export const missedRecurringPaymentData = /* GraphQL */ `query MissedRecurringPaymentData($recurring_id: String!) {
  missedRecurringPaymentData(recurring_id: $recurring_id) {
    fee
    number_of_payments_missed
    total_amount_owed
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MissedRecurringPaymentDataQueryVariables,
  APITypes.MissedRecurringPaymentDataQuery
>;
export const nativeApps = /* GraphQL */ `query NativeApps {
  nativeApps {
    app_id
    description
    device_check_key
    device_check_kid
    merchant_uid
    platform
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NativeAppsQueryVariables,
  APITypes.NativeAppsQuery
>;
export const paymentLinkCheckoutPage = /* GraphQL */ `query PaymentLinkCheckoutPage($link_id: String!) {
  paymentLinkCheckoutPage(link_id: $link_id) {
    accepted_payment_methods
    account_code
    amount
    amount_is_variable
    call_to_action
    created_date
    currency
    custom_success_message
    fee_mode
    is_active
    link_id
    link_name
    link_url
    max_amount
    merchant_uid
    min_amount
    payment_name
    payment_description
    redirect_url
    require_phone
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentLinkCheckoutPageQueryVariables,
  APITypes.PaymentLinkCheckoutPageQuery
>;
export const paymentLinks = /* GraphQL */ `query PaymentLinks(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  paymentLinks(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      accepted_payment_methods
      account_code
      amount
      amount_is_variable
      call_to_action
      created_date
      currency
      custom_success_message
      fee_mode
      is_active
      link_id
      link_name
      link_url
      max_amount
      merchant_uid
      min_amount
      payment_name
      payment_description
      redirect_url
      require_phone
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentLinksQueryVariables,
  APITypes.PaymentLinksQuery
>;
export const paymentMethodTokens = /* GraphQL */ `query PaymentMethodTokens(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  paymentMethodTokens(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      address_line1
      address_line2
      card_brand
      city
      country
      exp_date
      full_name
      is_active
      last_four
      merchant_uid
      metadata
      payment_method_id
      payment_type
      postal_code
      region
      wallet_type
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentMethodTokensQueryVariables,
  APITypes.PaymentMethodTokensQuery
>;
export const payors = /* GraphQL */ `query Payors(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  payors(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      address_line1
      address_line2
      city
      country
      email
      full_name
      merchant_uid
      metadata
      payor_id
      phone
      postal_code
      region
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<APITypes.PayorsQueryVariables, APITypes.PayorsQuery>;
export const recurringUpdatePage = /* GraphQL */ `query RecurringUpdatePage($recurring_hash: String!) {
  recurringUpdatePage(recurring_hash: $recurring_hash) {
    amount
    api_key
    hash
    merchant_name
    payor_id
    payor_name
    recurring_description
    recurring_name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecurringUpdatePageQueryVariables,
  APITypes.RecurringUpdatePageQuery
>;
export const recurringPayments = /* GraphQL */ `query RecurringPayments(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  recurringPayments(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      amount_per_payment
      created_date
      currency
      fee_mode
      fee_per_payment
      is_active
      is_processing
      recurring_id
      merchant_uid
      metadata
      mute_all_emails
      next_payment_date
      payment_interval
      prev_payment_date
      recurring_description
      recurring_name
      reference
      remaining_payments
      status
      total_amount_per_payment
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecurringPaymentsQueryVariables,
  APITypes.RecurringPaymentsQuery
>;
export const serviceFeeAmount = /* GraphQL */ `query ServiceFeeAmount($merchant_uid: String!, $amount: Int!) {
  serviceFeeAmount(merchant_uid: $merchant_uid, amount: $amount) {
    ach {
      adjusted_total
      fee
      fee_limit_reached
      total
      __typename
    }
    card {
      adjusted_total
      fee
      fee_limit_reached
      total
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServiceFeeAmountQueryVariables,
  APITypes.ServiceFeeAmountQuery
>;
export const settlements = /* GraphQL */ `query Settlements(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  settlements(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      currency
      gross_amount
      merchant_uid
      net_amount
      settlement_batch
      settlement_date
      status
      transaction_debit_count
      transaction_dispute_count
      transaction_reversal_count
      transfer_date
      total_adjustments
      total_fees
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SettlementsQueryVariables,
  APITypes.SettlementsQuery
>;
export const supportConversation = /* GraphQL */ `query SupportConversation($merchant_uid: ID!, $ticket_id: ID!) {
  supportConversation(merchant_uid: $merchant_uid, ticket_id: $ticket_id) {
    support_notes {
      body
      __typename
    }
    ticket_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SupportConversationQueryVariables,
  APITypes.SupportConversationQuery
>;
export const transactions = /* GraphQL */ `query Transactions(
  $query: SqlQuery
  $limit: Int
  $offset: String
  $direction: MoveDirection
  $offset_id: String
) {
  transactions(
    query: $query
    limit: $limit
    offset: $offset
    direction: $direction
    offset_id: $offset_id
  ) {
    items {
      account_code
      authorization_id
      currency
      device_id
      dispute_status
      failure_reasons
      fee_mode
      fees
      is_settled
      gross_amount
      merchant_uid
      metadata
      net_amount
      parent_id
      reference
      refund_voidable
      refunded_amount
      sale_id
      settlement_batch
      status
      timezone
      transaction_date
      transaction_id
      transaction_type
      updated_row_at
      __typename
    }
    total_row_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsQueryVariables,
  APITypes.TransactionsQuery
>;
export const users = /* GraphQL */ `query Users($user_pool: UserPool!, $merchant_uid: String) {
  users(user_pool: $user_pool, merchant_uid: $merchant_uid) {
    email
    full_name
    phone
    user_status
    username
    __typename
  }
}
` as GeneratedQuery<APITypes.UsersQueryVariables, APITypes.UsersQuery>;
