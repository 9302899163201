import {GeneratedQuery, ParsedResponse} from "./util";
import {
    Merchant,
    MerchantQuery,
    MerchantQueryVariables,
    MerchantSettingsInput,
    PaymentParameters,
    UpdateMerchantSettingsMutationVariables
} from "./API/types";
import {generateClient} from "aws-amplify/api";
import {defaultPaymentParameters} from "./API/graphql/queries";
import { updateMerchantSettings as updateMerchantSettingsMutation } from "./API/graphql/mutations";


const queryMerchant = `query getMerchant($merchant_uid: String) {
  merchant(merchant_uid: $merchant_uid) {
    settings {
      contact_email
      contact_phone
      website
      twitter
      tiktok
      instagram
      facebook
      linkedin
    }
    parent_merchant_uid
    merchant_uid
    merchant_name
    is_system
    fee_model {
      interchange_plus
      merchant_fee {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
      }
      service_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee_min {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
    }
    cash_active
    card_active
    api_key
    ach_active
    submitted_onboarding
  }
}
` as GeneratedQuery<MerchantQueryVariables, MerchantQuery>;

export const query = async (uid: string): Promise<ParsedResponse<Merchant>>  => {
    const client = generateClient()
    const variables = {
        merchant_uid: uid
    }
    try {
        const response = await client.graphql({ query: queryMerchant, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.merchant!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch merchant']}
    }
}

export const paymentParams = async (): Promise<ParsedResponse<PaymentParameters>> => {
    const client = generateClient()
    try {
        const response = await client.graphql({ query: defaultPaymentParameters })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.defaultPaymentParameters!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch payment parameters']}
    }
}

export const updateMerchantSettings = async (merchant_uid: string,
                                             settings: MerchantSettingsInput): Promise<ParsedResponse<boolean>> => {
    const client = generateClient()
    const variables: UpdateMerchantSettingsMutationVariables = {
        merchant_uid,
        settings
    }
    try {
        const response = await client.graphql({ query: updateMerchantSettingsMutation, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: true, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to update merchant settings']}
    }
}