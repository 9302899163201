import {
    CreatePaymentLinkMutationVariables,
    PaymentLink,
    PaymentLinks,
    QueryPair,
    UpdatePaymentLinkMutationVariables
} from "./API/types";
import {ListParams, ParsedResponse} from "./util";
import {generateClient} from "aws-amplify/api";
import {paymentLinks} from "./API/graphql/queries";
import {createPaymentLink, updatePaymentLink} from "./API/graphql/mutations";


export const list = async (params: ListParams<PaymentLink, QueryPair[]>): Promise<ParsedResponse<PaymentLinks>> => {
    const client = generateClient()
    let sort = [{key: 'created_date', direction: params.order}]
    const queryObject = {
        query_list: params.filter,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: params.offset?.link_id,
        offset: params.offset?.created_date,
        limit: params.limit,
        direction: params.direction
    }
    try {
        const response = await client.graphql({ query: paymentLinks, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.paymentLinks!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch payment links']}
    }
}

export const create = async (variables: CreatePaymentLinkMutationVariables): Promise<ParsedResponse<PaymentLink>> => {
    const client = generateClient()
    try {
        const response = await client.graphql({query: createPaymentLink, variables: variables})
        if (response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.createPaymentLink!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to create payment link']}
    }
}

export const update = async (variables: UpdatePaymentLinkMutationVariables): Promise<ParsedResponse<PaymentLink>> => {
    const client = generateClient()
    try {
        const response = await client.graphql({query: updatePaymentLink, variables: variables})
        if (response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.updatePaymentLink!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to update payment link']}
    }
}

