import {Notification, NotificationContextId, SubscribeNotificationSubscriptionVariables} from "./API/types";
import { generateClient } from "aws-amplify/api";
import { subscribeNotification } from "./API/graphql/subscriptions";

export const subscribe = (contextId: NotificationContextId , merchant_uid: string, onNext: (value: Notification) => void, onError?: (error: any) => void, primaryValue?: string, secondaryValue?: string) => {
    const client = generateClient()
    const variables: SubscribeNotificationSubscriptionVariables  = {
        context_id: contextId,
        merchant_uid: merchant_uid,
        primary_value: primaryValue,
        secondary_value: secondaryValue
    }
    return client.graphql({ query: subscribeNotification, variables: variables })
        .subscribe({
            next: ({data}) => onNext(data.subscribeNotification),
            error: err => {
                console.log(err)
                if(onError) onError(err)
            }
        })
}