/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type PaymentMethodInput = {
  ach?: AchInput | null,
  card?: CardInput | null,
  metadata?: string | null,
  payor?: PayorInput | null,
  payor_id?: string | null,
};

export type AchInput = {
  address_line1?: string | null,
  address_line2?: string | null,
  account_number: string,
  account_type: AchAccountType,
  city?: string | null,
  country?: string | null,
  name_on_account: string,
  postal_code?: string | null,
  region?: string | null,
  routing_number: string,
};

export enum AchAccountType {
  BUSINESS_CHECKING = "BUSINESS_CHECKING",
  BUSINESS_SAVINGS = "BUSINESS_SAVINGS",
  PERSONAL_CHECKING = "PERSONAL_CHECKING",
  PERSONAL_SAVINGS = "PERSONAL_SAVINGS",
}


export type CardInput = {
  address_line1?: string | null,
  address_line2?: string | null,
  card_number: string,
  city?: string | null,
  country?: string | null,
  exp_date: CardExpirationInput,
  full_name?: string | null,
  postal_code: string,
  region?: string | null,
  security_code: string,
};

export type CardExpirationInput = {
  month: string,
  year: string,
};

export type PayorInput = {
  address_line1?: string | null,
  address_line2?: string | null,
  city?: string | null,
  country?: string | null,
  email?: string | null,
  full_name?: string | null,
  merchant_uid?: string | null,
  metadata?: string | null,
  phone?: string | null,
  postal_code?: string | null,
  region?: string | null,
};

export enum FeeMode {
  INTERCHANGE = "INTERCHANGE",
  MERCHANT_FEE = "MERCHANT_FEE",
  SERVICE_FEE = "SERVICE_FEE",
}


export enum HealthExpenseType {
  CLINICAL = "CLINICAL",
  COPAY = "COPAY",
  DENTAL = "DENTAL",
  HEALTHCARE = "HEALTHCARE",
  RX = "RX",
  TRANSIT = "TRANSIT",
  VISION = "VISION",
}


export type DigitalWalletInput = {
  address_line1?: string | null,
  dpan: string,
  exp_date: string,
  postal_code?: string | null,
  wallet_type: WalletType,
};

export enum WalletType {
  APPLE_PAY = "APPLE_PAY",
  CLICK_TO_PAY = "CLICK_TO_PAY",
  GOOGLE_PAY = "GOOGLE_PAY",
  SAMSUNG_PAY = "SAMSUNG_PAY",
  VISA_STAGED = "VISA_STAGED",
}


export type AdditionalPurchaseDataInput = {
  // Max 99 line items
  level3_data_line_item?: Array< Level3DataLineItemInput | null > | null,
  level3_data_summary?: Level3DataSummaryInput | null,
};

export type Level3DataLineItemInput = {
  item_code?: string | null,
  item_description?: string | null,
  item_qty_exp?: number | null,
  prod_code?: string | null,
  qty?: number | null,
  tax_amount?: number | null,
  tax_ind?: TaxIndicatorType | null,
  tax_rate?: number | null,
  tax_rt_exp?: number | null,
  tax_type_id?: TaxType | null,
  unit_cost?: number | null,
  unit_of_msure?: string | null,
};

export enum TaxIndicatorType {
  NO_TAX_INFO_PROVIDED = "NO_TAX_INFO_PROVIDED",
  NOT_TAXABLE = "NOT_TAXABLE",
  TAX_AMOUNT_PROVIDED = "TAX_AMOUNT_PROVIDED",
}


export enum TaxType {
  CITY_SALES = "CITY_SALES",
  ENERGY = "ENERGY",
  GST = "GST",
  LOCAL_SALES = "LOCAL_SALES",
  MUNICIPAL_SALES = "MUNICIPAL_SALES",
  NATIONAL_SALES = "NATIONAL_SALES",
  NOT_SUPPORTED = "NOT_SUPPORTED",
  OTHER = "OTHER",
  PST = "PST",
  ROOM = "ROOM",
  OCCUPANCY = "OCCUPANCY",
  STATE_SALES = "STATE_SALES",
  UNKNOWN = "UNKNOWN",
  VAT = "VAT",
}


export type Level3DataSummaryInput = {
  dest_postal_code?: string | null,
  discnt_amt?: number | null,
  duty_amt?: number | null,
  frght_amt?: number | null,
  order_num?: string | null,
  prod_desc?: Array< string | null > | null,
  purch_idfr?: string | null,
  tax_amt?: number | null,
  tax_ind?: TaxIndicatorType | null,
};

export type Authorization = {
  __typename: "Authorization",
  account_code?: string | null,
  additional_purchase_data?: AdditionalPurchaseData | null,
  amount: number,
  authorization_date: string,
  authorization_id: string,
  captured_amount?: number | null,
  currency: string,
  device_id?: string | null,
  expiration_date?: string | null,
  failure_reasons?: Array< string | null > | null,
  fee_mode: FeeMode,
  fees: number,
  invoice?: Invoice | null,
  merchant_uid: string,
  metadata?: string | null,
  payment_method: PaymentMethodToken,
  reference?: string | null,
  sale_id?: string | null,
  status: AuthorizationStatus,
  timezone?: string | null,
  transaction_id?: string | null,
  updated_row_at?: string | null,
};

export type AdditionalPurchaseData = {
  __typename: "AdditionalPurchaseData",
  level3_data_line_item?:  Array<Level3DataLineItem | null > | null,
  level3_data_summary?: Level3DataSummary | null,
};

export type Level3DataLineItem = {
  __typename: "Level3DataLineItem",
  item_code?: string | null,
  item_description?: string | null,
  item_qty_exp?: number | null,
  prod_code?: string | null,
  qty?: number | null,
  tax_amount?: number | null,
  tax_ind?: TaxIndicatorType | null,
  tax_rate?: number | null,
  tax_rt_exp?: number | null,
  tax_type_id?: TaxType | null,
  unit_cost?: number | null,
  unit_of_msure?: string | null,
};

export type Level3DataSummary = {
  __typename: "Level3DataSummary",
  dest_postal_code?: string | null,
  discnt_amt?: number | null,
  duty_amt?: number | null,
  frght_amt?: number | null,
  order_num?: string | null,
  prod_desc?: Array< string | null > | null,
  purch_idfr?: string | null,
  tax_amt?: number | null,
  tax_ind?: TaxIndicatorType | null,
};

export type Invoice = {
  __typename: "Invoice",
  account_code?: string | null,
  created_date?: string | null,
  currency?: string | null,
  due_by?: string | null,
  fee_mode?: FeeMode | null,
  invoice_amount?: number | null,
  invoice_date?: string | null,
  invoice_description?: string | null,
  invoice_id?: string | null,
  invoice_name?: string | null,
  merchant_invoice_number?: string | null,
  merchant_uid?: string | null,
  metadata?: string | null,
  offline_transactions?:  Array<OfflineTransaction | null > | null,
  payor?: Payor | null,
  reference?: string | null,
  settings?: InvoiceSettings | null,
  status?: InvoiceStatus | null,
  total_paid_amount?: number | null,
};

export type OfflineTransaction = {
  __typename: "OfflineTransaction",
  amount?: number | null,
  instance_id?: string | null,
  invoice_id?: string | null,
  note?: string | null,
  transaction_date?: string | null,
  type?: OfflineTransactionType | null,
};

export enum OfflineTransactionType {
  ACH = "ACH",
  CARD = "CARD",
  CASH = "CASH",
  OTHER = "OTHER",
}


export type Payor = {
  __typename: "Payor",
  address_line1?: string | null,
  address_line2?: string | null,
  city?: string | null,
  country?: string | null,
  email?: string | null,
  full_name?: string | null,
  merchant_uid?: string | null,
  metadata?: string | null,
  payor_id?: string | null,
  phone?: string | null,
  postal_code?: string | null,
  region?: string | null,
};

export type InvoiceSettings = {
  __typename: "InvoiceSettings",
  accepted_payment_methods?: AcceptedPaymentMethods | null,
  is_secure?: boolean | null,
  require_payor_address?: boolean | null,
  security_pin?: string | null,
};

export type AcceptedPaymentMethods = {
  __typename: "AcceptedPaymentMethods",
  ach?: boolean | null,
  card?: boolean | null,
  cash?: boolean | null,
};

export enum InvoiceStatus {
  NOT_PAID = "NOT_PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAID = "PAID",
}


export type PaymentMethodToken = {
  __typename: "PaymentMethodToken",
  address_line1?: string | null,
  address_line2?: string | null,
  card_brand?: string | null,
  city?: string | null,
  country?: string | null,
  exp_date?: string | null,
  full_name?: string | null,
  is_active?: boolean | null,
  last_four?: string | null,
  merchant_uid?: string | null,
  metadata?: string | null,
  payment_method_id?: string | null,
  payment_type?: PaymentType | null,
  payor?: Payor | null,
  postal_code?: string | null,
  region?: string | null,
  wallet_type?: WalletType | null,
};

export enum PaymentType {
  ACH = "ACH",
  CARD = "CARD",
  CASH = "CASH",
}


export enum AuthorizationStatus {
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}


export enum BackofficeKeyEntityLevel {
  MERCHANT = "MERCHANT",
  PARTNER = "PARTNER",
  SYSTEM = "SYSTEM",
}


export type BackofficeKeyDataWithKey = {
  __typename: "BackofficeKeyDataWithKey",
  api_key?: string | null,
  created_date?: string | null,
  enabled?: boolean | null,
  entity_level?: BackofficeKeyEntityLevel | null,
  key_name?: string | null,
  last_accessed_date?: string | null,
  merchant_uid?: string | null,
};

export enum TransactionStatus {
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  RETURNED = "RETURNED",
  SETTLED = "SETTLED",
  SUCCEEDED = "SUCCEEDED",
  VOIDED = "VOIDED",
}


export type CardPresentPayment = {
  __typename: "CardPresentPayment",
  amount?: number | null,
  card_brand?: string | null,
  created_at?: string | null,
  currency?: string | null,
  failure_reason?: Array< string | null > | null,
  last_four?: string | null,
  service_fee?: number | null,
  status?: TransactionStatus | null,
  transaction_id?: string | null,
};

export type Transaction = {
  __typename: "Transaction",
  account_code?: string | null,
  ach_return_details?: AchReturnDetails | null,
  additional_purchase_data?: AdditionalPurchaseData | null,
  authorization_id?: string | null,
  currency?: string | null,
  device_id?: string | null,
  dispute_status?: DisputeStatus | null,
  failure_reasons?: Array< string | null > | null,
  fee_mode?: FeeMode | null,
  fees?: number | null,
  invoice?: Invoice | null,
  is_settled?: boolean | null,
  gross_amount?: number | null,
  merchant_uid?: string | null,
  metadata?: string | null,
  net_amount?: number | null,
  parent_id?: string | null,
  payment_method?: PaymentMethodToken | null,
  recurring?: RecurringPayment | null,
  reference?: string | null,
  refund_reason?: RefundReason | null,
  refund_voidable?: boolean | null,
  refunded_amount?: number | null,
  sale_id?: string | null,
  settlement_batch?: number | null,
  status?: TransactionStatus | null,
  timezone?: string | null,
  transaction_date?: string | null,
  transaction_id?: string | null,
  transaction_type?: TransactionType | null,
  updated_row_at?: string | null,
};

export type AchReturnDetails = {
  __typename: "AchReturnDetails",
  return_code?: string | null,
  return_details?: string | null,
  transfer_type?: AchReturnTransferType | null,
};

export enum AchReturnTransferType {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
}


export enum DisputeStatus {
  INQUIRY = "INQUIRY",
  LOST = "LOST",
  PENDING = "PENDING",
  WON = "WON",
}


export type RecurringPayment = {
  __typename: "RecurringPayment",
  account_code?: string | null,
  amount_per_payment?: number | null,
  created_date?: string | null,
  currency?: string | null,
  fee_mode?: FeeMode | null,
  fee_per_payment?: number | null,
  is_active?: boolean | null,
  is_processing?: boolean | null,
  recurring_id?: string | null,
  merchant_uid?: string | null,
  metadata?: string | null,
  mute_all_emails?: boolean | null,
  next_payment_date?: string | null,
  payment_interval?: RecurringInterval | null,
  payment_method?: PaymentMethodToken | null,
  payor?: Payor | null,
  prev_payment_date?: string | null,
  recurring_description?: string | null,
  recurring_name?: string | null,
  reference?: string | null,
  remaining_payments?: number | null,
  status?: RecurringStatus | null,
  total_amount_per_payment?: number | null,
};

export enum RecurringInterval {
  ANNUAL = "ANNUAL",
  BI_ANNUAL = "BI_ANNUAL",
  BI_WEEKLY = "BI_WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  WEEKLY = "WEEKLY",
}


export enum RecurringStatus {
  INSTRUMENT_FAILURE = "INSTRUMENT_FAILURE",
  SUCCESS = "SUCCESS",
  SYSTEM_FAILURE = "SYSTEM_FAILURE",
}


export type RefundReason = {
  __typename: "RefundReason",
  reason_code?: RefundReasonCode | null,
  reason_details?: string | null,
};

export enum RefundReasonCode {
  DUPLICATE = "DUPLICATE",
  FRAUDULENT = "FRAUDULENT",
  OTHER = "OTHER",
  REQUESTED_BY_CUSTOMER = "REQUESTED_BY_CUSTOMER",
}


export enum TransactionType {
  ACH_RETURN = "ACH_RETURN",
  DEBIT = "DEBIT",
  FAILURE = "FAILURE",
  REVERSAL = "REVERSAL",
}


export type DeviceInput = {
  device_id?: string | null,
  device_description: string,
  device_name: string,
  device_properties?: string | null,
  is_active?: boolean | null,
  merchant_uid: string,
  processor: string,
  processor_device_id: string,
};

export type Device = {
  __typename: "Device",
  device_id?: string | null,
  device_description?: string | null,
  device_name?: string | null,
  device_properties?: string | null,
  is_active?: boolean | null,
  merchant_uid?: string | null,
  processor?: string | null,
  processor_device_id?: string | null,
};

export type InvoiceInput = {
  account_code?: string | null,
  currency: string,
  due_by?: string | null,
  fee_mode?: FeeMode | null,
  invoice_amount: number,
  invoice_date?: string | null,
  invoice_description?: string | null,
  invoice_name?: string | null,
  merchant_uid: string,
  merchant_invoice_number?: string | null,
  metadata?: string | null,
  payor_id?: string | null,
  payor?: PayorInput | null,
  reference?: string | null,
  send_email?: boolean | null,
  settings?: InvoiceSettingsInput | null,
};

export type InvoiceSettingsInput = {
  accepted_payment_methods?: AcceptedPaymentMethodsInput | null,
  is_secure?: boolean | null,
  require_payor_address?: boolean | null,
};

export type AcceptedPaymentMethodsInput = {
  ach?: boolean | null,
  card?: boolean | null,
  cash?: boolean | null,
};

export type MerchantUserInput = {
  email: string,
  first_name: string,
  last_name: string,
  phone?: string | null,
};

export type ListMerchant = {
  __typename: "ListMerchant",
  ach_active?: boolean | null,
  card_active?: boolean | null,
  cash_active?: boolean | null,
  is_system?: boolean | null,
  merchant_name?: string | null,
  merchant_uid?: string | null,
  parent_merchant_uid?: string | null,
  submitted_onboarding?: boolean | null,
  updated_row_at?: string | null,
};

export type MerchantOnboardingInput = {
  bank?: BusinessBankInput | null,
  business?: BusinessInput | null,
  business_owners?: Array< BusinessOwnerInput | null > | null,
  merchant_uid: string,
  needs_docs?: boolean | null,
  ticket_id?: number | null,
  user_email?: string | null,
  user_full_name?: string | null,
};

export type BusinessBankInput = {
  account_number?: string | null,
  account_type?: BANK_ACCOUNT_TYPE | null,
  bank_code?: string | null,
  name?: string | null,
};

export enum BANK_ACCOUNT_TYPE {
  CK = "CK",
  CORP_CK = "CORP_CK",
  CORP_SV = "CORP_SV",
  SV = "SV",
}


export type BusinessInput = {
  additional_underwriting_data?: AdditionalUnderwritingDataInput | null,
  underwriting_data?: UnderwritingDataInput | null,
};

export type AdditionalUnderwritingDataInput = {
  annual_ach_volume?: number | null,
  average_ach_transfer_amount?: number | null,
  average_card_transfer_amount?: number | null,
  business_description?: string | null,
  card_volume_distribution?: CardVolumeDistributionInput | null,
  credit_check_allowed?: boolean | null,
  credit_check_ip_address?: string | null,
  credit_check_timestamp?: string | null,
  credit_check_user_agent?: string | null,
  merchant_agreement_accepted?: boolean | null,
  merchant_agreement_ip_address?: string | null,
  merchant_agreement_timestamp?: string | null,
  merchant_agreement_user_agent?: string | null,
  statement_descriptor?: string | null,
  refund_policy?: REFUND_POLICY | null,
  volume_distribution_by_business_type?: VolumeDistributionByBusinessTypeInput | null,
};

export type CardVolumeDistributionInput = {
  card_present_percentage?: number | null,
  ecommerce_percentage?: number | null,
};

export enum REFUND_POLICY {
  EXCHANGE = "EXCHANGE",
  NONE = "NONE",
  OTHER = "OTHER",
  THIRTY = "THIRTY",
}


export type VolumeDistributionByBusinessTypeInput = {
  business_to_business_volume_percentage?: number | null,
  business_to_consumer_volume_percentage?: number | null,
  other_volume_percentage?: number | null,
};

export type UnderwritingDataInput = {
  annual_card_volume?: number | null,
  business_address?: AddressInput | null,
  has_accepted_credit_cards_previously?: boolean | null,
  business_name?: string | null,
  business_type?: BUSINESS_TYPE | null,
  doing_business_as?: string | null,
  incorporation_date?: string | null,
  max_transaction_amount?: number | null,
  mcc?: string | null,
  phone?: string | null,
  ownership_type?: OWNERSHIP_TYPE | null,
  tax_id?: string | null,
  url?: string | null,
};

export type AddressInput = {
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  region?: string | null,
};

export enum BUSINESS_TYPE {
  CORP = "CORP",
  GOV = "GOV",
  INTERNATIONAL = "INTERNATIONAL",
  LLC = "LLC",
  PARTNERSHIP = "PARTNERSHIP",
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
  TAX_EXEMPT = "TAX_EXEMPT",
  TRUST = "TRUST",
}


export enum OWNERSHIP_TYPE {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}


export type BusinessOwnerInput = {
  address?: AddressInput | null,
  dob?: string | null,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  middle_initial?: string | null,
  percentage_ownership?: number | null,
  phone?: string | null,
  primary_owner?: boolean | null,
  tax_id?: string | null,
  title?: string | null,
  uid: string,
};

export type SupportNoteInput = {
  body?: string | null,
  support_docs?: Array< DocumentInput | null > | null,
};

export type DocumentInput = {
  // Base 64 encoded file data
  body: string,
  doc_type: SUPPORT_DOCUMENT_TYPE,
  // The mime type (will be used as is)
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  mime_type: string,
  // The name of the file
  name: string,
};

export enum SUPPORT_DOCUMENT_TYPE {
  BANK_STATEMENT = "BANK_STATEMENT",
  BUSINESS_ADDRESS_VERIFICATION = "BUSINESS_ADDRESS_VERIFICATION",
  BUSINESS_REGISTRATION = "BUSINESS_REGISTRATION",
  DRIVERS_LICENSE_BACK = "DRIVERS_LICENSE_BACK",
  DRIVERS_LICENSE_FRONT = "DRIVERS_LICENSE_FRONT",
  IDENTIFICATION_CARD_BACK = "IDENTIFICATION_CARD_BACK",
  IDENTIFICATION_CARD_FRONT = "IDENTIFICATION_CARD_FRONT",
  PASSPORT = "PASSPORT",
  PCI_DOCUMENT = "PCI_DOCUMENT",
  OTHER = "OTHER",
  TAX_DOCUMENT = "TAX_DOCUMENT",
}


export type NativeAppInput = {
  app_id: string,
  description?: string | null,
  device_check_key?: string | null,
  device_check_kid?: string | null,
  merchant_uid: string,
  platform: NativeAppPlatform,
};

export enum NativeAppPlatform {
  ANDROID = "ANDROID",
  APPLE = "APPLE",
}


export enum NotificationContextId {
  DISPUTE_CHANGE = "DISPUTE_CHANGE",
  DISPUTE_EVIDENCE_DELETED = "DISPUTE_EVIDENCE_DELETED",
  DISPUTE_EVIDENCE_SENT = "DISPUTE_EVIDENCE_SENT",
  DISPUTE_EVIDENCE_UPLOADED = "DISPUTE_EVIDENCE_UPLOADED",
}


export type Notification = {
  __typename: "Notification",
  context_id: NotificationContextId,
  error?: string | null,
  merchant_uid: string,
  primary_value?: string | null,
  secondary_value?: string | null,
};

export type OfflineTransactionInput = {
  amount: number,
  invoice_id: string,
  note?: string | null,
  transaction_date: string,
  type: OfflineTransactionType,
};

export type OneTimePayment = {
  __typename: "OneTimePayment",
  amount?: number | null,
  card_brand?: string | null,
  created_at?: string | null,
  currency?: string | null,
  failure_reason?: string | null,
  last_four?: string | null,
  service_fee?: number | null,
  status?: TransactionStatus | null,
  transaction_id?: string | null,
};

export type PaymentLinkInput = {
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
  account_code?: string | null,
  amount?: number | null,
  amount_is_variable?: boolean | null,
  call_to_action?: CallToActionType | null,
  currency?: string | null,
  custom_success_message?: string | null,
  fee_mode?: FeeMode | null,
  link_name: string,
  max_amount?: number | null,
  merchant_uid: string,
  min_amount?: number | null,
  payment_description?: string | null,
  payment_name: string,
  redirect_url?: string | null,
  require_phone?: boolean | null,
};

export enum AcceptedPaymentMethodsEnum {
  ALL = "ALL",
  NOT_ACH = "NOT_ACH",
  NOT_CARD = "NOT_CARD",
  NOT_CASH = "NOT_CASH",
  ONLY_ACH = "ONLY_ACH",
  ONLY_CARD = "ONLY_CARD",
  ONLY_CASH = "ONLY_CASH",
}


export enum CallToActionType {
  BOOK = "BOOK",
  DONATE = "DONATE",
  PAY = "PAY",
}


export type PaymentLink = {
  __typename: "PaymentLink",
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
  account_code?: string | null,
  amount?: number | null,
  amount_is_variable?: boolean | null,
  call_to_action?: CallToActionType | null,
  created_date?: string | null,
  currency?: string | null,
  custom_success_message?: string | null,
  fee_mode?: FeeMode | null,
  is_active?: boolean | null,
  link_id?: string | null,
  link_name?: string | null,
  link_url?: string | null,
  max_amount?: number | null,
  merchant_uid?: string | null,
  min_amount?: number | null,
  payment_name?: string | null,
  payment_description?: string | null,
  redirect_url?: string | null,
  require_phone?: boolean | null,
};

export type RecurringPaymentInput = {
  account_code?: string | null,
  amount: number,
  fee_mode?: FeeMode | null,
  first_payment_date?: string | null,
  merchant_uid: string,
  metadata?: string | null,
  mute_all_emails?: boolean | null,
  payment_count?: number | null,
  payment_interval: RecurringInterval,
  payment_method_id: string,
  payor?: PayorInput | null,
  payor_id?: string | null,
  recurring_description?: string | null,
  recurring_name: string,
  reference?: string | null,
};

export type RefundReasonInput = {
  reason_code: RefundReasonCode,
  reason_details?: string | null,
};

export type UserInput = {
  bypass_merchant_validation?: boolean | null,
  email: string,
  first_name: string,
  last_name: string,
  merchant_uid?: string | null,
  phone?: string | null,
  user_pool: UserPool,
};

export enum UserPool {
  MERCHANT = "MERCHANT",
  PARTNER = "PARTNER",
  SYSTEM = "SYSTEM",
}


export type User = {
  __typename: "User",
  email?: string | null,
  full_name?: string | null,
  phone?: string | null,
  user_status?: string | null,
  username?: string | null,
};

export enum MetadataAssociate {
  AUTHORIZATION = "AUTHORIZATION",
  INVOICE = "INVOICE",
  PAYMENT_INTENT = "PAYMENT_INTENT",
  PAYMENT_METHOD_TOKEN = "PAYMENT_METHOD_TOKEN",
  PAYMENT_SESSION = "PAYMENT_SESSION",
  PAYOR = "PAYOR",
  RECURRING = "RECURRING",
  TRANSACTION = "TRANSACTION",
  SALE = "SALE",
}


export type InvoiceUpdateInput = {
  account_code?: string | null,
  currency?: string | null,
  due_by?: string | null,
  fee_mode?: FeeMode | null,
  invoice_amount?: number | null,
  invoice_date?: string | null,
  invoice_name?: string | null,
  invoice_description?: string | null,
  merchant_invoice_number?: string | null,
  reference?: string | null,
  send_email?: boolean | null,
  settings?: InvoiceSettingsInput | null,
};

export type MerchantSettingsInput = {
  contact_email?: string | null,
  contact_phone?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  linkedin?: string | null,
  tiktok?: string | null,
  twitter?: string | null,
  website?: string | null,
};

export type UpdatePaymentLinkInput = {
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
  account_code?: string | null,
  amount?: number | null,
  amount_is_variable?: boolean | null,
  call_to_action?: CallToActionType | null,
  currency?: string | null,
  custom_success_message?: string | null,
  fee_mode?: FeeMode | null,
  is_active?: boolean | null,
  link_id: string,
  link_name?: string | null,
  max_amount?: number | null,
  merchant_uid: string,
  min_amount?: number | null,
  payment_description?: string | null,
  payment_name?: string | null,
  redirect_url?: string | null,
  require_phone?: boolean | null,
};

export type PayorData = {
  address_line1?: string | null,
  address_line2?: string | null,
  city?: string | null,
  country?: string | null,
  email?: string | null,
  full_name?: string | null,
  phone?: string | null,
  postal_code?: string | null,
  region?: string | null,
};

export type UpdateRecurringPaymentInput = {
  mute_all_emails?: boolean | null,
  pay_all_missed_payments?: boolean | null,
  payment_method_id?: string | null,
  recurring_id: string,
};

export type SqlQuery = {
  query_list?: Array< QueryPair | null > | null,
  sort_list?: Array< SortPair | null > | null,
};

export type QueryPair = {
  conjunctive_operator?: ConjunctiveOperator | null,
  in_values?: Array< string | null > | null,
  key?: string | null,
  operator?: Operator | null,
  query_group?: Array< QueryPair | null > | null,
  value?: string | null,
};

export enum ConjunctiveOperator {
  AND_NEXT = "AND_NEXT",
  NONE_NEXT = "NONE_NEXT",
  OR_NEXT = "OR_NEXT",
}


export enum Operator {
  EQUAL = "EQUAL",
  EQUAL_FALSE = "EQUAL_FALSE",
  EQUAL_TRUE = "EQUAL_TRUE",
  GREATER_EQUAL = "GREATER_EQUAL",
  GREATER_THAN = "GREATER_THAN",
  IN_LIST = "IN_LIST",
  IS_NOT_NULL = "IS_NOT_NULL",
  IS_NULL = "IS_NULL",
  LESS_EQUAL = "LESS_EQUAL",
  LESS_THAN = "LESS_THAN",
  LIKE = "LIKE",
  NOT_EQUAL = "NOT_EQUAL",
  NOT_IN_LIST = "NOT_IN_LIST",
  NOT_LIKE = "NOT_LIKE",
}


export type SortPair = {
  direction?: SortDirection | null,
  key?: string | null,
};

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export enum MoveDirection {
  BACKWARD = "BACKWARD",
  FORWARD = "FORWARD",
}


export type Authorizations = {
  __typename: "Authorizations",
  items?:  Array<Authorization | null > | null,
  total_row_count?: number | null,
};

export type BackofficeKeyData = {
  __typename: "BackofficeKeyData",
  created_date?: string | null,
  enabled?: boolean | null,
  entity_level?: BackofficeKeyEntityLevel | null,
  key_name?: string | null,
  last_accessed_date?: string | null,
  merchant_uid?: string | null,
};

export type PaymentParameters = {
  __typename: "PaymentParameters",
  denied_after_date?: string | null,
  denied_before_date?: string | null,
  enabled: boolean,
  expires_in?: number | null,
  maximum_amount?: number | null,
  maximum_occurrence?: number | null,
  minimum_amount?: number | null,
  payment_parameters_name?: string | null,
  payment_parameters_type: string,
  recurrence_period?: string | null,
  validation_link_url?: string | null,
};

export type Devices = {
  __typename: "Devices",
  items?:  Array<Device | null > | null,
  total_row_count?: number | null,
};

export type DisputeEvidence = {
  __typename: "DisputeEvidence",
  dispute_id?: string | null,
  evidence_date?: string | null,
  filename?: string | null,
  merchant_uid?: string | null,
  status?: EvidenceStatus | null,
  updated_date?: string | null,
};

export enum EvidenceStatus {
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
}


export enum AwsS3Action {
  DELETE = "DELETE",
  GET = "GET",
  PUT = "PUT",
}


export type Disputes = {
  __typename: "Disputes",
  items?:  Array<Dispute | null > | null,
  total_row_count?: number | null,
};

export type Dispute = {
  __typename: "Dispute",
  amount?: number | null,
  dispute_date?: string | null,
  dispute_id?: string | null,
  evidence_last_send_date?: string | null,
  expiration_date?: string | null,
  merchant_uid?: string | null,
  status?: DisputeStatus | null,
  reason?: DisputeReason | null,
  reason_message?: string | null,
  settlement_deposit_batch?: string | null,
  settlement_withdrawal_batch?: string | null,
  transaction?: Transaction | null,
  updated_date?: string | null,
  updated_row_at?: string | null,
};

export enum DisputeReason {
  CLERICAL = "CLERICAL",
  FRAUD = "FRAUD",
  INQUIRY = "INQUIRY",
  QUALITY = "QUALITY",
  TECHNICAL = "TECHNICAL",
}


export type CSVInput = {
  end_time?: string | null,
  locale?: string | null,
  merchant_uid?: string | null,
  report_name_prefix?: string | null,
  source?: string | null,
  start_time?: string | null,
};

export type CSVOutput = {
  __typename: "CSVOutput",
  bucket_name?: string | null,
  filepaths?: Array< string | null > | null,
};

export type HostedCheckout = {
  __typename: "HostedCheckout",
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
  account_code?: string | null,
  amount?: number | null,
  api_key?: string | null,
  call_to_action?: CallToActionType | null,
  checkout_type?: string | null,
  currency?: string | null,
  fee_mode?: FeeMode | null,
  invoice_id?: string | null,
  is_active?: boolean | null,
  merchant_name?: string | null,
  merchant_uid?: string | null,
  metadata?: string | null,
  origin?: string | null,
  payment_description?: string | null,
  payment_name?: string | null,
  payment_parameters?: string | null,
  payor?: Payor | null,
  recurring_id?: string | null,
  require_phone?: boolean | null,
};

export type InvoiceForCheckout = {
  __typename: "InvoiceForCheckout",
  account_code?: string | null,
  api_key?: string | null,
  currency?: string | null,
  fee_mode?: FeeMode | null,
  invoice_amount?: number | null,
  invoice_description?: string | null,
  invoice_id?: string | null,
  invoice_name?: string | null,
  merchant_name?: string | null,
  merchant_uid?: string | null,
  payor?: Payor | null,
  reference?: string | null,
  settings?: InvoiceSettings | null,
  status?: InvoiceStatus | null,
  total_paid_amount?: number | null,
};

export type Invoices = {
  __typename: "Invoices",
  items?:  Array<Invoice | null > | null,
  total_row_count?: number | null,
};

export type Merchant = {
  __typename: "Merchant",
  ach_active?: boolean | null,
  api_key?: string | null,
  card_active?: boolean | null,
  cash_active?: boolean | null,
  fee_model?: FeeModel | null,
  is_system?: boolean | null,
  merchant_name?: string | null,
  merchant_uid?: string | null,
  parent_merchant_uid?: string | null,
  settings?: MerchantSettings | null,
  submitted_onboarding?: boolean | null,
};

export type FeeModel = {
  __typename: "FeeModel",
  interchange_plus?: boolean | null,
  merchant_fee?: FeeModelDetail | null,
  service_fee?: FeeModelDetail | null,
  service_fee_min?: FeeModelDetail | null,
};

export type FeeModelDetail = {
  __typename: "FeeModelDetail",
  ach_basis?: number | null,
  ach_fixed?: number | null,
  card_basis?: number | null,
  card_fixed?: number | null,
};

export type MerchantSettings = {
  __typename: "MerchantSettings",
  contact_email?: string | null,
  contact_phone?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  linkedin?: string | null,
  tiktok?: string | null,
  twitter?: string | null,
  website?: string | null,
};

export type MerchantOnboarding = {
  __typename: "MerchantOnboarding",
  bank?: BusinessBank | null,
  business?: Business | null,
  business_owners?:  Array<BusinessOwner | null > | null,
  is_locked?: boolean | null,
  merchant_uid: string,
  needs_docs?: boolean | null,
  ticket_id?: number | null,
  user_email?: string | null,
  user_full_name?: string | null,
};

export type BusinessBank = {
  __typename: "BusinessBank",
  account_number?: string | null,
  account_type?: BANK_ACCOUNT_TYPE | null,
  bank_code?: string | null,
  name?: string | null,
};

export type Business = {
  __typename: "Business",
  additional_underwriting_data?: AdditionalUnderwritingData | null,
  underwriting_data?: UnderwritingData | null,
};

export type AdditionalUnderwritingData = {
  __typename: "AdditionalUnderwritingData",
  annual_ach_volume?: number | null,
  average_ach_transfer_amount?: number | null,
  average_card_transfer_amount?: number | null,
  business_description?: string | null,
  card_volume_distribution?: CardVolumeDistribution | null,
  credit_check_allowed?: boolean | null,
  credit_check_ip_address?: string | null,
  credit_check_timestamp?: string | null,
  credit_check_user_agent?: string | null,
  merchant_agreement_accepted?: boolean | null,
  merchant_agreement_ip_address?: string | null,
  merchant_agreement_timestamp?: string | null,
  merchant_agreement_user_agent?: string | null,
  statement_descriptor?: string | null,
  refund_policy?: REFUND_POLICY | null,
  volume_distribution_by_business_type?: VolumeDistributionByBusinessType | null,
};

export type CardVolumeDistribution = {
  __typename: "CardVolumeDistribution",
  card_present_percentage?: number | null,
  ecommerce_percentage?: number | null,
};

export type VolumeDistributionByBusinessType = {
  __typename: "VolumeDistributionByBusinessType",
  business_to_business_volume_percentage?: number | null,
  business_to_consumer_volume_percentage?: number | null,
  other_volume_percentage?: number | null,
};

export type UnderwritingData = {
  __typename: "UnderwritingData",
  annual_card_volume?: number | null,
  business_address?: Address | null,
  has_accepted_credit_cards_previously?: boolean | null,
  business_name?: string | null,
  business_type?: BUSINESS_TYPE | null,
  doing_business_as?: string | null,
  incorporation_date?: string | null,
  max_transaction_amount?: number | null,
  mcc?: string | null,
  phone?: string | null,
  ownership_type?: OWNERSHIP_TYPE | null,
  tax_id?: string | null,
  url?: string | null,
};

export type Address = {
  __typename: "Address",
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  region?: string | null,
};

export type BusinessOwner = {
  __typename: "BusinessOwner",
  address?: Address | null,
  dob?: string | null,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  middle_initial?: string | null,
  percentage_ownership?: number | null,
  phone?: string | null,
  primary_owner?: boolean | null,
  tax_id?: string | null,
  title?: string | null,
  uid: string,
};

export type Merchants = {
  __typename: "Merchants",
  items?:  Array<ListMerchant | null > | null,
  total_row_count?: number | null,
};

export type MissedRecurringPaymentData = {
  __typename: "MissedRecurringPaymentData",
  fee?: number | null,
  number_of_payments_missed?: number | null,
  total_amount_owed?: number | null,
};

export type NativeApp = {
  __typename: "NativeApp",
  app_id?: string | null,
  description?: string | null,
  device_check_key?: string | null,
  device_check_kid?: string | null,
  merchant_uid?: string | null,
  platform?: NativeAppPlatform | null,
};

export type PaymentLinkForCheckout = {
  __typename: "PaymentLinkForCheckout",
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
  account_code?: string | null,
  amount?: number | null,
  amount_is_variable?: boolean | null,
  api_key?: string | null,
  call_to_action?: CallToActionType | null,
  currency?: string | null,
  custom_success_message?: string | null,
  fee_mode?: FeeMode | null,
  is_active?: boolean | null,
  max_amount?: number | null,
  merchant_name?: string | null,
  merchant_uid?: string | null,
  min_amount?: number | null,
  payment_description?: string | null,
  payment_name?: string | null,
  redirect_url?: string | null,
  require_phone?: boolean | null,
};

export type PaymentLinks = {
  __typename: "PaymentLinks",
  items?:  Array<PaymentLink | null > | null,
  total_row_count?: number | null,
};

export type PaymentMethodTokens = {
  __typename: "PaymentMethodTokens",
  items?:  Array<PaymentMethodToken | null > | null,
  total_row_count?: number | null,
};

export type Payors = {
  __typename: "Payors",
  items?:  Array<Payor | null > | null,
  total_row_count?: number | null,
};

export type RecurringUpdateDetails = {
  __typename: "RecurringUpdateDetails",
  amount?: number | null,
  api_key?: string | null,
  hash?: string | null,
  merchant_name?: string | null,
  payor_id?: string | null,
  payor_name?: string | null,
  recurring_description?: string | null,
  recurring_name?: string | null,
};

export type RecurringPayments = {
  __typename: "RecurringPayments",
  items?:  Array<RecurringPayment | null > | null,
  total_row_count?: number | null,
};

export type ServiceFeeAmount = {
  __typename: "ServiceFeeAmount",
  ach?: ServiceFeeCalculation | null,
  card?: ServiceFeeCalculation | null,
};

export type ServiceFeeCalculation = {
  __typename: "ServiceFeeCalculation",
  adjusted_total?: number | null,
  fee?: number | null,
  fee_limit_reached?: boolean | null,
  total?: number | null,
};

export type Settlements = {
  __typename: "Settlements",
  items?:  Array<Settlement | null > | null,
  total_row_count?: number | null,
};

export type Settlement = {
  __typename: "Settlement",
  currency?: string | null,
  gross_amount?: number | null,
  merchant_uid?: string | null,
  net_amount?: number | null,
  settlement_batch?: number | null,
  settlement_date?: string | null,
  status?: string | null,
  transaction_debit_count?: number | null,
  transaction_dispute_count?: number | null,
  transaction_reversal_count?: number | null,
  transfer_date?: string | null,
  total_adjustments?: number | null,
  total_fees?: number | null,
  updated_row_at?: string | null,
};

export type SupportConversation = {
  __typename: "SupportConversation",
  support_notes?:  Array<SupportNote | null > | null,
  ticket_id: string,
};

export type SupportNote = {
  __typename: "SupportNote",
  body?: string | null,
  support_docs?:  Array<Document | null > | null,
};

export type Document = {
  __typename: "Document",
  attachment_id: string,
  doc_type: SUPPORT_DOCUMENT_TYPE,
  name: string,
};

export type Transactions = {
  __typename: "Transactions",
  items?:  Array<Transaction | null > | null,
  total_row_count?: number | null,
};

export type CreateAuthorizationMutationVariables = {
  merchant_uid: string,
  sale_id?: string | null,
  amount: number,
  payment_method_id?: string | null,
  payment_method?: PaymentMethodInput | null,
  invoice_id?: string | null,
  fee?: number | null,
  fee_mode?: FeeMode | null,
  account_code?: string | null,
  reference?: string | null,
  metadata?: string | null,
  health_expense_type?: HealthExpenseType | null,
  digital_wallet?: DigitalWalletInput | null,
  additional_purchase_data?: AdditionalPurchaseDataInput | null,
};

export type CreateAuthorizationMutation = {
  // Create an authorization
  createAuthorization:  {
    __typename: "Authorization",
    account_code?: string | null,
    additional_purchase_data?:  {
      __typename: "AdditionalPurchaseData",
    } | null,
    amount: number,
    authorization_date: string,
    authorization_id: string,
    captured_amount?: number | null,
    currency: string,
    device_id?: string | null,
    expiration_date?: string | null,
    failure_reasons?: Array< string | null > | null,
    fee_mode: FeeMode,
    fees: number,
    invoice?:  {
      __typename: "Invoice",
      account_code?: string | null,
      created_date?: string | null,
      currency?: string | null,
      due_by?: string | null,
      fee_mode?: FeeMode | null,
      invoice_amount?: number | null,
      invoice_date?: string | null,
      invoice_description?: string | null,
      invoice_id?: string | null,
      invoice_name?: string | null,
      merchant_invoice_number?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      reference?: string | null,
      status?: InvoiceStatus | null,
      total_paid_amount?: number | null,
    } | null,
    merchant_uid: string,
    metadata?: string | null,
    payment_method:  {
      __typename: "PaymentMethodToken",
      address_line1?: string | null,
      address_line2?: string | null,
      card_brand?: string | null,
      city?: string | null,
      country?: string | null,
      exp_date?: string | null,
      full_name?: string | null,
      is_active?: boolean | null,
      last_four?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payment_method_id?: string | null,
      payment_type?: PaymentType | null,
      postal_code?: string | null,
      region?: string | null,
      wallet_type?: WalletType | null,
    },
    reference?: string | null,
    sale_id?: string | null,
    status: AuthorizationStatus,
    timezone?: string | null,
    transaction_id?: string | null,
    updated_row_at?: string | null,
  },
};

export type CreateBackOfficeKeyMutationVariables = {
  merchant_uid: string,
  entity_level: BackofficeKeyEntityLevel,
  key_name?: string | null,
};

export type CreateBackOfficeKeyMutation = {
  createBackOfficeKey?:  {
    __typename: "BackofficeKeyDataWithKey",
    api_key?: string | null,
    created_date?: string | null,
    enabled?: boolean | null,
    entity_level?: BackofficeKeyEntityLevel | null,
    key_name?: string | null,
    last_accessed_date?: string | null,
    merchant_uid?: string | null,
  } | null,
};

export type CreateCardPresentCompletionMutationVariables = {
  status: TransactionStatus,
  amount?: number | null,
  card_brand?: string | null,
  last_four?: string | null,
  service_fee?: number | null,
  transaction_id: string,
  created_at?: string | null,
  failure_reason?: Array< string | null > | null,
};

export type CreateCardPresentCompletionMutation = {
  createCardPresentCompletion?:  {
    __typename: "CardPresentPayment",
    amount?: number | null,
    card_brand?: string | null,
    created_at?: string | null,
    currency?: string | null,
    failure_reason?: Array< string | null > | null,
    last_four?: string | null,
    service_fee?: number | null,
    status?: TransactionStatus | null,
    transaction_id?: string | null,
  } | null,
};

export type CreateCaptureMutationVariables = {
  merchant_uid: string,
  authorization_id: string,
  amount: number,
  send_receipt?: boolean | null,
  receipt_description?: string | null,
  allow_reauth?: boolean | null,
  allow_exceeded_amount?: boolean | null,
};

export type CreateCaptureMutation = {
  // Create a capture for the passed in authorization ID
  createCapture:  {
    __typename: "Transaction",
    account_code?: string | null,
    ach_return_details?:  {
      __typename: "AchReturnDetails",
      return_code?: string | null,
      return_details?: string | null,
      transfer_type?: AchReturnTransferType | null,
    } | null,
    additional_purchase_data?:  {
      __typename: "AdditionalPurchaseData",
    } | null,
    authorization_id?: string | null,
    currency?: string | null,
    device_id?: string | null,
    dispute_status?: DisputeStatus | null,
    failure_reasons?: Array< string | null > | null,
    fee_mode?: FeeMode | null,
    fees?: number | null,
    invoice?:  {
      __typename: "Invoice",
      account_code?: string | null,
      created_date?: string | null,
      currency?: string | null,
      due_by?: string | null,
      fee_mode?: FeeMode | null,
      invoice_amount?: number | null,
      invoice_date?: string | null,
      invoice_description?: string | null,
      invoice_id?: string | null,
      invoice_name?: string | null,
      merchant_invoice_number?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      reference?: string | null,
      status?: InvoiceStatus | null,
      total_paid_amount?: number | null,
    } | null,
    is_settled?: boolean | null,
    gross_amount?: number | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    net_amount?: number | null,
    parent_id?: string | null,
    payment_method?:  {
      __typename: "PaymentMethodToken",
      address_line1?: string | null,
      address_line2?: string | null,
      card_brand?: string | null,
      city?: string | null,
      country?: string | null,
      exp_date?: string | null,
      full_name?: string | null,
      is_active?: boolean | null,
      last_four?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payment_method_id?: string | null,
      payment_type?: PaymentType | null,
      postal_code?: string | null,
      region?: string | null,
      wallet_type?: WalletType | null,
    } | null,
    recurring?:  {
      __typename: "RecurringPayment",
      account_code?: string | null,
      amount_per_payment?: number | null,
      created_date?: string | null,
      currency?: string | null,
      fee_mode?: FeeMode | null,
      fee_per_payment?: number | null,
      is_active?: boolean | null,
      is_processing?: boolean | null,
      recurring_id?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      mute_all_emails?: boolean | null,
      next_payment_date?: string | null,
      payment_interval?: RecurringInterval | null,
      prev_payment_date?: string | null,
      recurring_description?: string | null,
      recurring_name?: string | null,
      reference?: string | null,
      remaining_payments?: number | null,
      status?: RecurringStatus | null,
      total_amount_per_payment?: number | null,
    } | null,
    reference?: string | null,
    refund_reason?:  {
      __typename: "RefundReason",
      reason_code?: RefundReasonCode | null,
      reason_details?: string | null,
    } | null,
    refund_voidable?: boolean | null,
    refunded_amount?: number | null,
    sale_id?: string | null,
    settlement_batch?: number | null,
    status?: TransactionStatus | null,
    timezone?: string | null,
    transaction_date?: string | null,
    transaction_id?: string | null,
    transaction_type?: TransactionType | null,
    updated_row_at?: string | null,
  },
};

export type CreateCardPresentPaymentMutationVariables = {
  merchant_uid: string,
  device_id: string,
  payor_id?: string | null,
  payor?: PayorInput | null,
  amount: number,
  recurring_id?: string | null,
  invoice_id?: string | null,
  fee?: number | null,
  fee_mode?: FeeMode | null,
  payment_parameters_name?: string | null,
  account_code?: string | null,
  reference?: string | null,
  send_receipt?: boolean | null,
  receipt_description?: string | null,
  timezone?: string | null,
  metadata?: string | null,
};

export type CreateCardPresentPaymentMutation = {
  createCardPresentPayment?: string | null,
};

export type CreateDeviceMutationVariables = {
  input: DeviceInput,
};

export type CreateDeviceMutation = {
  createDevice?:  {
    __typename: "Device",
    device_id?: string | null,
    device_description?: string | null,
    device_name?: string | null,
    device_properties?: string | null,
    is_active?: boolean | null,
    merchant_uid?: string | null,
    processor?: string | null,
    processor_device_id?: string | null,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: InvoiceInput,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    account_code?: string | null,
    created_date?: string | null,
    currency?: string | null,
    due_by?: string | null,
    fee_mode?: FeeMode | null,
    invoice_amount?: number | null,
    invoice_date?: string | null,
    invoice_description?: string | null,
    invoice_id?: string | null,
    invoice_name?: string | null,
    merchant_invoice_number?: string | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    offline_transactions?:  Array< {
      __typename: "OfflineTransaction",
      amount?: number | null,
      instance_id?: string | null,
      invoice_id?: string | null,
      note?: string | null,
      transaction_date?: string | null,
      type?: OfflineTransactionType | null,
    } | null > | null,
    payor?:  {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null,
    reference?: string | null,
    settings?:  {
      __typename: "InvoiceSettings",
      is_secure?: boolean | null,
      require_payor_address?: boolean | null,
      security_pin?: string | null,
    } | null,
    status?: InvoiceStatus | null,
    total_paid_amount?: number | null,
  } | null,
};

export type CreateInvoiceEmailMutationVariables = {
  invoice_id: string,
};

export type CreateInvoiceEmailMutation = {
  createInvoiceEmail?: boolean | null,
};

export type CreateMerchantMutationVariables = {
  merchant_name: string,
  parent_merchant_uid?: string | null,
  user?: MerchantUserInput | null,
};

export type CreateMerchantMutation = {
  // Create a merchant by calling the pt-merchant service
  createMerchant?:  {
    __typename: "ListMerchant",
    ach_active?: boolean | null,
    card_active?: boolean | null,
    cash_active?: boolean | null,
    is_system?: boolean | null,
    merchant_name?: string | null,
    merchant_uid?: string | null,
    parent_merchant_uid?: string | null,
    submitted_onboarding?: boolean | null,
    updated_row_at?: string | null,
  } | null,
};

export type CreateMerchantOnboardingMutationVariables = {
  merchant_onboarding: MerchantOnboardingInput,
  support_note?: SupportNoteInput | null,
  is_form_completed: boolean,
};

export type CreateMerchantOnboardingMutation = {
  // Upsert the onboarding data with the option, to also include some initial supporting doc
  createMerchantOnboarding?: boolean | null,
};

export type CreateMerchantOnboardingDocumentSubmissionMutationVariables = {
  merchant_uid: string,
  ticket_id: string,
  attachment_ids: Array< string | null >,
  processor: string,
};

export type CreateMerchantOnboardingDocumentSubmissionMutation = {
  // Submit supporting docs to the processor (stored in fresh desk)
  createMerchantOnboardingDocumentSubmission?: boolean | null,
};

export type CreateMerchantOnboardingStatusChangeMutationVariables = {
  merchant_uid: string,
  want_to_unlock_data?: boolean | null,
  want_to_ask_for_docs?: boolean | null,
};

export type CreateMerchantOnboardingStatusChangeMutation = {
  // Change the statuses of key values for the onboarding data
  createMerchantOnboardingStatusChange?: boolean | null,
};

export type CreateMerchantOnboardingSubmissionMutationVariables = {
  merchant_uid: string,
  processor: string,
};

export type CreateMerchantOnboardingSubmissionMutation = {
  // Submit the onboarding data to the processor
  createMerchantOnboardingSubmission?: boolean | null,
};

export type CreateNativeAppMutationVariables = {
  input: NativeAppInput,
};

export type CreateNativeAppMutation = {
  createNativeApp?: boolean | null,
};

export type CreateNotificationMutationVariables = {
  merchant_uid: string,
  context_id: NotificationContextId,
  primary_value?: string | null,
  secondary_value?: string | null,
  error?: string | null,
};

export type CreateNotificationMutation = {
  // Create an notification echo to trigger the subscription
  createNotification?:  {
    __typename: "Notification",
    context_id: NotificationContextId,
    error?: string | null,
    merchant_uid: string,
    primary_value?: string | null,
    secondary_value?: string | null,
  } | null,
};

export type CreateOfflineTransactionMutationVariables = {
  input: OfflineTransactionInput,
};

export type CreateOfflineTransactionMutation = {
  createOfflineTransaction?:  {
    __typename: "OfflineTransaction",
    amount?: number | null,
    instance_id?: string | null,
    invoice_id?: string | null,
    note?: string | null,
    transaction_date?: string | null,
    type?: OfflineTransactionType | null,
  } | null,
};

export type CreateOneTimePaymentMutationVariables = {
  merchant_uid: string,
  amount: number,
  payment_method_id?: string | null,
  payment_method?: PaymentMethodInput | null,
  recurring_id?: string | null,
  invoice_id?: string | null,
  fee?: number | null,
  fee_mode?: FeeMode | null,
  payment_parameters_name?: string | null,
  account_code?: string | null,
  reference?: string | null,
  send_receipt?: boolean | null,
  receipt_description?: string | null,
  metadata?: string | null,
  health_expense_type?: HealthExpenseType | null,
  digital_wallet?: DigitalWalletInput | null,
  additional_purchase_data?: AdditionalPurchaseDataInput | null,
};

export type CreateOneTimePaymentMutation = {
  createOneTimePayment?:  {
    __typename: "OneTimePayment",
    amount?: number | null,
    card_brand?: string | null,
    created_at?: string | null,
    currency?: string | null,
    failure_reason?: string | null,
    last_four?: string | null,
    service_fee?: number | null,
    status?: TransactionStatus | null,
    transaction_id?: string | null,
  } | null,
};

export type CreatePaymentLinkMutationVariables = {
  input: PaymentLinkInput,
};

export type CreatePaymentLinkMutation = {
  createPaymentLink?:  {
    __typename: "PaymentLink",
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
    account_code?: string | null,
    amount?: number | null,
    amount_is_variable?: boolean | null,
    call_to_action?: CallToActionType | null,
    created_date?: string | null,
    currency?: string | null,
    custom_success_message?: string | null,
    fee_mode?: FeeMode | null,
    is_active?: boolean | null,
    link_id?: string | null,
    link_name?: string | null,
    link_url?: string | null,
    max_amount?: number | null,
    merchant_uid?: string | null,
    min_amount?: number | null,
    payment_name?: string | null,
    payment_description?: string | null,
    redirect_url?: string | null,
    require_phone?: boolean | null,
  } | null,
};

export type CreatePaymentMethodMutationVariables = {
  payment_method: PaymentMethodInput,
  merchant_uid: string,
};

export type CreatePaymentMethodMutation = {
  createPaymentMethod?:  {
    __typename: "PaymentMethodToken",
    address_line1?: string | null,
    address_line2?: string | null,
    card_brand?: string | null,
    city?: string | null,
    country?: string | null,
    exp_date?: string | null,
    full_name?: string | null,
    is_active?: boolean | null,
    last_four?: string | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    payment_method_id?: string | null,
    payment_type?: PaymentType | null,
    payor?:  {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null,
    postal_code?: string | null,
    region?: string | null,
    wallet_type?: WalletType | null,
  } | null,
};

export type CreatePayorMutationVariables = {
  input: PayorInput,
};

export type CreatePayorMutation = {
  createPayor?:  {
    __typename: "Payor",
    address_line1?: string | null,
    address_line2?: string | null,
    city?: string | null,
    country?: string | null,
    email?: string | null,
    full_name?: string | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    payor_id?: string | null,
    phone?: string | null,
    postal_code?: string | null,
    region?: string | null,
  } | null,
};

export type CreateReceiptEmailMutationVariables = {
  transaction_id: string,
  receipt_description?: string | null,
  email?: string | null,
};

export type CreateReceiptEmailMutation = {
  createReceiptEmail?: boolean | null,
};

export type CreateRecurringPaymentMutationVariables = {
  input: RecurringPaymentInput,
};

export type CreateRecurringPaymentMutation = {
  createRecurringPayment?:  {
    __typename: "RecurringPayment",
    account_code?: string | null,
    amount_per_payment?: number | null,
    created_date?: string | null,
    currency?: string | null,
    fee_mode?: FeeMode | null,
    fee_per_payment?: number | null,
    is_active?: boolean | null,
    is_processing?: boolean | null,
    recurring_id?: string | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    mute_all_emails?: boolean | null,
    next_payment_date?: string | null,
    payment_interval?: RecurringInterval | null,
    payment_method?:  {
      __typename: "PaymentMethodToken",
      address_line1?: string | null,
      address_line2?: string | null,
      card_brand?: string | null,
      city?: string | null,
      country?: string | null,
      exp_date?: string | null,
      full_name?: string | null,
      is_active?: boolean | null,
      last_four?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payment_method_id?: string | null,
      payment_type?: PaymentType | null,
      postal_code?: string | null,
      region?: string | null,
      wallet_type?: WalletType | null,
    } | null,
    payor?:  {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null,
    prev_payment_date?: string | null,
    recurring_description?: string | null,
    recurring_name?: string | null,
    reference?: string | null,
    remaining_payments?: number | null,
    status?: RecurringStatus | null,
    total_amount_per_payment?: number | null,
  } | null,
};

export type CancelRecurringPaymentMutationVariables = {
  recurring_id: string,
};

export type CancelRecurringPaymentMutation = {
  cancelRecurringPayment?: boolean | null,
};

export type CreateRefundMutationVariables = {
  transaction_id: string,
  amount: number,
  refund_reason: RefundReasonInput,
  refund_email?: string | null,
};

export type CreateRefundMutation = {
  createRefund?: boolean | null,
};

export type CreateRetryForFailedRecurringPaymentMutationVariables = {
  recurring_id: string,
};

export type CreateRetryForFailedRecurringPaymentMutation = {
  createRetryForFailedRecurringPayment?: boolean | null,
};

export type CreateSendDisputeEvidenceMutationVariables = {
  dispute_id: string,
  merchant_uid?: string | null,
};

export type CreateSendDisputeEvidenceMutation = {
  // Send unsent evidence records for a dispute to the processor
  createSendDisputeEvidence?: boolean | null,
};

export type CreateSuccessOnboardingEmailMutationVariables = {
  merchant_uid: string,
};

export type CreateSuccessOnboardingEmailMutation = {
  // Send the success email to the merchant
  createSuccessOnboardingEmail?: boolean | null,
};

export type CreateSupportNoteMutationVariables = {
  merchant_uid: string,
  ticket_id: string,
  support_note: SupportNoteInput,
};

export type CreateSupportNoteMutation = {
  // Upload supporting docs to fresh desk as a note with attachments
  createSupportNote?: boolean | null,
};

export type CreateUserMutationVariables = {
  input: UserInput,
};

export type CreateUserMutation = {
  // Allows us to create a user while bypassing the merchant validation
  createUser?:  {
    __typename: "User",
    email?: string | null,
    full_name?: string | null,
    phone?: string | null,
    user_status?: string | null,
    username?: string | null,
  } | null,
};

export type CreateUserPasswordResetMutationVariables = {
  username: string,
  user_pool: UserPool,
};

export type CreateUserPasswordResetMutation = {
  createUserPasswordReset?: boolean | null,
};

export type CreateVoidForRefundMutationVariables = {
  transaction_id: string,
};

export type CreateVoidForRefundMutation = {
  createVoidForRefund?: boolean | null,
};

export type DeleteBackOfficeKeyMutationVariables = {
  merchant_uid: string,
  key_name: string,
};

export type DeleteBackOfficeKeyMutation = {
  deleteBackOfficeKey?: boolean | null,
};

export type DeleteDeviceMutationVariables = {
  merchant_uid: string,
  device_id: string,
};

export type DeleteDeviceMutation = {
  deleteDevice?: boolean | null,
};

export type DeleteDisputeEvidenceMutationVariables = {
  dispute_id: string,
  filename: string,
  merchant_uid: string,
};

export type DeleteDisputeEvidenceMutation = {
  // Delete an evidence record for a dispute
  deleteDisputeEvidence?: boolean | null,
};

export type DeleteInvoiceMutationVariables = {
  invoice_id: string,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?: boolean | null,
};

export type DeleteMetadataMutationVariables = {
  id: string,
  metadata_associate: MetadataAssociate,
  merchant_uid: string,
  metadata_keys: Array< string | null >,
};

export type DeleteMetadataMutation = {
  deleteMetadata?: boolean | null,
};

export type DeleteNativeAppMutationVariables = {
  merchant_uid: string,
  app_id: string,
};

export type DeleteNativeAppMutation = {
  deleteNativeApp?: boolean | null,
};

export type DeleteUserMutationVariables = {
  username: string,
  user_pool: UserPool,
};

export type DeleteUserMutation = {
  deleteUser?: boolean | null,
};

export type UpdateBackOfficeKeyMutationVariables = {
  merchant_uid: string,
  key_name: string,
  enabled?: boolean | null,
};

export type UpdateBackOfficeKeyMutation = {
  updateBackOfficeKey?: boolean | null,
};

export type UpdateDeviceMutationVariables = {
  merchant_uid: string,
  device_id: string,
  device_description: string,
};

export type UpdateDeviceMutation = {
  updateDevice?: boolean | null,
};

export type UpdateInvoiceMutationVariables = {
  invoice_id: string,
  invoice_update_input: InvoiceUpdateInput,
};

export type UpdateInvoiceMutation = {
  // Update any invoice fields except the payor
  updateInvoice?: boolean | null,
};

export type UpdateMerchantSettingsMutationVariables = {
  merchant_uid: string,
  settings: MerchantSettingsInput,
};

export type UpdateMerchantSettingsMutation = {
  // Update the settings for a pt-merchant service record
  updateMerchantSettings?: boolean | null,
};

export type UpdateMetadataMutationVariables = {
  id: string,
  metadata_associate: MetadataAssociate,
  merchant_uid: string,
  metadata: string,
};

export type UpdateMetadataMutation = {
  updateMetadata?: boolean | null,
};

export type UpdateNativeAppMutationVariables = {
  input: NativeAppInput,
};

export type UpdateNativeAppMutation = {
  updateNativeApp?: boolean | null,
};

export type UpdatePaymentLinkMutationVariables = {
  input: UpdatePaymentLinkInput,
};

export type UpdatePaymentLinkMutation = {
  updatePaymentLink?:  {
    __typename: "PaymentLink",
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
    account_code?: string | null,
    amount?: number | null,
    amount_is_variable?: boolean | null,
    call_to_action?: CallToActionType | null,
    created_date?: string | null,
    currency?: string | null,
    custom_success_message?: string | null,
    fee_mode?: FeeMode | null,
    is_active?: boolean | null,
    link_id?: string | null,
    link_name?: string | null,
    link_url?: string | null,
    max_amount?: number | null,
    merchant_uid?: string | null,
    min_amount?: number | null,
    payment_name?: string | null,
    payment_description?: string | null,
    redirect_url?: string | null,
    require_phone?: boolean | null,
  } | null,
};

export type UpdatePayorMutationVariables = {
  payor_id: string,
  payor_data: PayorData,
};

export type UpdatePayorMutation = {
  updatePayor?: boolean | null,
};

export type UpdateRecurringPaymentMutationVariables = {
  input: UpdateRecurringPaymentInput,
};

export type UpdateRecurringPaymentMutation = {
  updateRecurringPayment?:  {
    __typename: "RecurringPayment",
    account_code?: string | null,
    amount_per_payment?: number | null,
    created_date?: string | null,
    currency?: string | null,
    fee_mode?: FeeMode | null,
    fee_per_payment?: number | null,
    is_active?: boolean | null,
    is_processing?: boolean | null,
    recurring_id?: string | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    mute_all_emails?: boolean | null,
    next_payment_date?: string | null,
    payment_interval?: RecurringInterval | null,
    payment_method?:  {
      __typename: "PaymentMethodToken",
      address_line1?: string | null,
      address_line2?: string | null,
      card_brand?: string | null,
      city?: string | null,
      country?: string | null,
      exp_date?: string | null,
      full_name?: string | null,
      is_active?: boolean | null,
      last_four?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payment_method_id?: string | null,
      payment_type?: PaymentType | null,
      postal_code?: string | null,
      region?: string | null,
      wallet_type?: WalletType | null,
    } | null,
    payor?:  {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null,
    prev_payment_date?: string | null,
    recurring_description?: string | null,
    recurring_name?: string | null,
    reference?: string | null,
    remaining_payments?: number | null,
    status?: RecurringStatus | null,
    total_amount_per_payment?: number | null,
  } | null,
};

export type UpdateRecurringPaymentMethodMutationVariables = {
  recurring_hash: string,
  payment_method_id: string,
};

export type UpdateRecurringPaymentMethodMutation = {
  updateRecurringPaymentMethod?:  {
    __typename: "RecurringPayment",
    account_code?: string | null,
    amount_per_payment?: number | null,
    created_date?: string | null,
    currency?: string | null,
    fee_mode?: FeeMode | null,
    fee_per_payment?: number | null,
    is_active?: boolean | null,
    is_processing?: boolean | null,
    recurring_id?: string | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    mute_all_emails?: boolean | null,
    next_payment_date?: string | null,
    payment_interval?: RecurringInterval | null,
    payment_method?:  {
      __typename: "PaymentMethodToken",
      address_line1?: string | null,
      address_line2?: string | null,
      card_brand?: string | null,
      city?: string | null,
      country?: string | null,
      exp_date?: string | null,
      full_name?: string | null,
      is_active?: boolean | null,
      last_four?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payment_method_id?: string | null,
      payment_type?: PaymentType | null,
      postal_code?: string | null,
      region?: string | null,
      wallet_type?: WalletType | null,
    } | null,
    payor?:  {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null,
    prev_payment_date?: string | null,
    recurring_description?: string | null,
    recurring_name?: string | null,
    reference?: string | null,
    remaining_payments?: number | null,
    status?: RecurringStatus | null,
    total_amount_per_payment?: number | null,
  } | null,
};

export type UpdateTransactionAccountCodeMutationVariables = {
  transaction_id: string,
  account_code: string,
};

export type UpdateTransactionAccountCodeMutation = {
  // Woo Commerce will call this endpoint to update the account code for the transaction with the Woo order identifier
  updateTransactionAccountCode?: boolean | null,
};

export type AuthorizationsQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type AuthorizationsQuery = {
  authorizations?:  {
    __typename: "Authorizations",
    items?:  Array< {
      __typename: "Authorization",
      account_code?: string | null,
      amount: number,
      authorization_date: string,
      authorization_id: string,
      captured_amount?: number | null,
      currency: string,
      device_id?: string | null,
      expiration_date?: string | null,
      failure_reasons?: Array< string | null > | null,
      fee_mode: FeeMode,
      fees: number,
      merchant_uid: string,
      metadata?: string | null,
      reference?: string | null,
      sale_id?: string | null,
      status: AuthorizationStatus,
      timezone?: string | null,
      transaction_id?: string | null,
      updated_row_at?: string | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type BackOfficeKeyQueryVariables = {
  merchant_uid: string,
  entity_level: BackofficeKeyEntityLevel,
};

export type BackOfficeKeyQuery = {
  backOfficeKey?:  Array< {
    __typename: "BackofficeKeyData",
    created_date?: string | null,
    enabled?: boolean | null,
    entity_level?: BackofficeKeyEntityLevel | null,
    key_name?: string | null,
    last_accessed_date?: string | null,
    merchant_uid?: string | null,
  } | null > | null,
};

export type DefaultPaymentParametersQueryVariables = {
};

export type DefaultPaymentParametersQuery = {
  // Get the default payment parameter row
  defaultPaymentParameters?:  {
    __typename: "PaymentParameters",
    denied_after_date?: string | null,
    denied_before_date?: string | null,
    enabled: boolean,
    expires_in?: number | null,
    maximum_amount?: number | null,
    maximum_occurrence?: number | null,
    minimum_amount?: number | null,
    payment_parameters_name?: string | null,
    payment_parameters_type: string,
    recurrence_period?: string | null,
    validation_link_url?: string | null,
  } | null,
};

export type DevicesQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type DevicesQuery = {
  devices?:  {
    __typename: "Devices",
    items?:  Array< {
      __typename: "Device",
      device_id?: string | null,
      device_description?: string | null,
      device_name?: string | null,
      device_properties?: string | null,
      is_active?: boolean | null,
      merchant_uid?: string | null,
      processor?: string | null,
      processor_device_id?: string | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type DisputeEvidenceQueryVariables = {
  dispute_id: string,
  merchant_uid?: string | null,
};

export type DisputeEvidenceQuery = {
  // Get dispute evidence records for a dispute
  disputeEvidence:  Array< {
    __typename: "DisputeEvidence",
    dispute_id?: string | null,
    evidence_date?: string | null,
    filename?: string | null,
    merchant_uid?: string | null,
    status?: EvidenceStatus | null,
    updated_date?: string | null,
  } | null >,
};

export type DisputeEvidenceTokensQueryVariables = {
  action: AwsS3Action,
  dispute_id: string,
  filenames: Array< string | null >,
  mime_types?: Array< string > | null,
  merchant_uid?: string | null,
};

export type DisputeEvidenceTokensQuery = {
  // Get AWS S3 access tokens for dispute evidence files
  // The mime types (will be used as is)
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  disputeEvidenceTokens: Array< string | null >,
};

export type DisputesQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type DisputesQuery = {
  disputes?:  {
    __typename: "Disputes",
    items?:  Array< {
      __typename: "Dispute",
      amount?: number | null,
      dispute_date?: string | null,
      dispute_id?: string | null,
      evidence_last_send_date?: string | null,
      expiration_date?: string | null,
      merchant_uid?: string | null,
      status?: DisputeStatus | null,
      reason?: DisputeReason | null,
      reason_message?: string | null,
      settlement_deposit_batch?: string | null,
      settlement_withdrawal_batch?: string | null,
      updated_date?: string | null,
      updated_row_at?: string | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type ErrorTextQueryVariables = {
  language_code: string,
  error_codes: Array< string | null >,
};

export type ErrorTextQuery = {
  // Convert the error code into a language specific error text
  errorText: Array< string | null >,
};

export type GenerateCSVQueryVariables = {
  csvInput: CSVInput,
};

export type GenerateCSVQuery = {
  generateCSV?:  {
    __typename: "CSVOutput",
    bucket_name?: string | null,
    filepaths?: Array< string | null > | null,
  } | null,
};

export type HostedCheckoutPageQueryVariables = {
  session_id: string,
};

export type HostedCheckoutPageQuery = {
  hostedCheckoutPage?:  {
    __typename: "HostedCheckout",
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
    account_code?: string | null,
    amount?: number | null,
    api_key?: string | null,
    call_to_action?: CallToActionType | null,
    checkout_type?: string | null,
    currency?: string | null,
    fee_mode?: FeeMode | null,
    invoice_id?: string | null,
    is_active?: boolean | null,
    merchant_name?: string | null,
    merchant_uid?: string | null,
    metadata?: string | null,
    origin?: string | null,
    payment_description?: string | null,
    payment_name?: string | null,
    payment_parameters?: string | null,
    payor?:  {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null,
    recurring_id?: string | null,
    require_phone?: boolean | null,
  } | null,
};

export type InvoiceCheckoutPageQueryVariables = {
  invoice_id: string,
};

export type InvoiceCheckoutPageQuery = {
  invoiceCheckoutPage?:  {
    __typename: "InvoiceForCheckout",
    account_code?: string | null,
    api_key?: string | null,
    currency?: string | null,
    fee_mode?: FeeMode | null,
    invoice_amount?: number | null,
    invoice_description?: string | null,
    invoice_id?: string | null,
    invoice_name?: string | null,
    merchant_name?: string | null,
    merchant_uid?: string | null,
    payor?:  {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null,
    reference?: string | null,
    settings?:  {
      __typename: "InvoiceSettings",
      is_secure?: boolean | null,
      require_payor_address?: boolean | null,
      security_pin?: string | null,
    } | null,
    status?: InvoiceStatus | null,
    total_paid_amount?: number | null,
  } | null,
};

export type InvoicesQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type InvoicesQuery = {
  invoices?:  {
    __typename: "Invoices",
    items?:  Array< {
      __typename: "Invoice",
      account_code?: string | null,
      created_date?: string | null,
      currency?: string | null,
      due_by?: string | null,
      fee_mode?: FeeMode | null,
      invoice_amount?: number | null,
      invoice_date?: string | null,
      invoice_description?: string | null,
      invoice_id?: string | null,
      invoice_name?: string | null,
      merchant_invoice_number?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      reference?: string | null,
      status?: InvoiceStatus | null,
      total_paid_amount?: number | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type MerchantQueryVariables = {
  merchant_uid?: string | null,
  merchant_name?: string | null,
};

export type MerchantQuery = {
  merchant?:  {
    __typename: "Merchant",
    ach_active?: boolean | null,
    api_key?: string | null,
    card_active?: boolean | null,
    cash_active?: boolean | null,
    fee_model?:  {
      __typename: "FeeModel",
      interchange_plus?: boolean | null,
    } | null,
    is_system?: boolean | null,
    merchant_name?: string | null,
    merchant_uid?: string | null,
    parent_merchant_uid?: string | null,
    settings?:  {
      __typename: "MerchantSettings",
      contact_email?: string | null,
      contact_phone?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      linkedin?: string | null,
      tiktok?: string | null,
      twitter?: string | null,
      website?: string | null,
    } | null,
    submitted_onboarding?: boolean | null,
  } | null,
};

export type MerchantOnboardingQueryVariables = {
  merchant_uid: string,
};

export type MerchantOnboardingQuery = {
  // Get onboarding data
  merchantOnboarding?:  {
    __typename: "MerchantOnboarding",
    bank?:  {
      __typename: "BusinessBank",
      account_number?: string | null,
      account_type?: BANK_ACCOUNT_TYPE | null,
      bank_code?: string | null,
      name?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
    } | null,
    business_owners?:  Array< {
      __typename: "BusinessOwner",
      dob?: string | null,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      middle_initial?: string | null,
      percentage_ownership?: number | null,
      phone?: string | null,
      primary_owner?: boolean | null,
      tax_id?: string | null,
      title?: string | null,
      uid: string,
    } | null > | null,
    is_locked?: boolean | null,
    merchant_uid: string,
    needs_docs?: boolean | null,
    ticket_id?: number | null,
    user_email?: string | null,
    user_full_name?: string | null,
  } | null,
};

export type MerchantsQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type MerchantsQuery = {
  merchants?:  {
    __typename: "Merchants",
    items?:  Array< {
      __typename: "ListMerchant",
      ach_active?: boolean | null,
      card_active?: boolean | null,
      cash_active?: boolean | null,
      is_system?: boolean | null,
      merchant_name?: string | null,
      merchant_uid?: string | null,
      parent_merchant_uid?: string | null,
      submitted_onboarding?: boolean | null,
      updated_row_at?: string | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type MissedRecurringPaymentDataQueryVariables = {
  recurring_id: string,
};

export type MissedRecurringPaymentDataQuery = {
  missedRecurringPaymentData?:  {
    __typename: "MissedRecurringPaymentData",
    fee?: number | null,
    number_of_payments_missed?: number | null,
    total_amount_owed?: number | null,
  } | null,
};

export type NativeAppsQueryVariables = {
};

export type NativeAppsQuery = {
  nativeApps?:  Array< {
    __typename: "NativeApp",
    app_id?: string | null,
    description?: string | null,
    device_check_key?: string | null,
    device_check_kid?: string | null,
    merchant_uid?: string | null,
    platform?: NativeAppPlatform | null,
  } | null > | null,
};

export type PaymentLinkCheckoutPageQueryVariables = {
  link_id: string,
};

export type PaymentLinkCheckoutPageQuery = {
  paymentLinkCheckoutPage?:  {
    __typename: "PaymentLinkForCheckout",
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
    account_code?: string | null,
    amount?: number | null,
    amount_is_variable?: boolean | null,
    api_key?: string | null,
    call_to_action?: CallToActionType | null,
    currency?: string | null,
    custom_success_message?: string | null,
    fee_mode?: FeeMode | null,
    is_active?: boolean | null,
    max_amount?: number | null,
    merchant_name?: string | null,
    merchant_uid?: string | null,
    min_amount?: number | null,
    payment_description?: string | null,
    payment_name?: string | null,
    redirect_url?: string | null,
    require_phone?: boolean | null,
  } | null,
};

export type PaymentLinksQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type PaymentLinksQuery = {
  paymentLinks?:  {
    __typename: "PaymentLinks",
    items?:  Array< {
      __typename: "PaymentLink",
      accepted_payment_methods?: AcceptedPaymentMethodsEnum | null,
      account_code?: string | null,
      amount?: number | null,
      amount_is_variable?: boolean | null,
      call_to_action?: CallToActionType | null,
      created_date?: string | null,
      currency?: string | null,
      custom_success_message?: string | null,
      fee_mode?: FeeMode | null,
      is_active?: boolean | null,
      link_id?: string | null,
      link_name?: string | null,
      link_url?: string | null,
      max_amount?: number | null,
      merchant_uid?: string | null,
      min_amount?: number | null,
      payment_name?: string | null,
      payment_description?: string | null,
      redirect_url?: string | null,
      require_phone?: boolean | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type PaymentMethodTokensQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type PaymentMethodTokensQuery = {
  paymentMethodTokens?:  {
    __typename: "PaymentMethodTokens",
    items?:  Array< {
      __typename: "PaymentMethodToken",
      address_line1?: string | null,
      address_line2?: string | null,
      card_brand?: string | null,
      city?: string | null,
      country?: string | null,
      exp_date?: string | null,
      full_name?: string | null,
      is_active?: boolean | null,
      last_four?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payment_method_id?: string | null,
      payment_type?: PaymentType | null,
      postal_code?: string | null,
      region?: string | null,
      wallet_type?: WalletType | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type PayorsQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type PayorsQuery = {
  // This queries payors
  payors?:  {
    __typename: "Payors",
    items?:  Array< {
      __typename: "Payor",
      address_line1?: string | null,
      address_line2?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      full_name?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      payor_id?: string | null,
      phone?: string | null,
      postal_code?: string | null,
      region?: string | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type RecurringUpdatePageQueryVariables = {
  recurring_hash: string,
};

export type RecurringUpdatePageQuery = {
  recurringUpdatePage?:  {
    __typename: "RecurringUpdateDetails",
    amount?: number | null,
    api_key?: string | null,
    hash?: string | null,
    merchant_name?: string | null,
    payor_id?: string | null,
    payor_name?: string | null,
    recurring_description?: string | null,
    recurring_name?: string | null,
  } | null,
};

export type RecurringPaymentsQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type RecurringPaymentsQuery = {
  recurringPayments?:  {
    __typename: "RecurringPayments",
    items?:  Array< {
      __typename: "RecurringPayment",
      account_code?: string | null,
      amount_per_payment?: number | null,
      created_date?: string | null,
      currency?: string | null,
      fee_mode?: FeeMode | null,
      fee_per_payment?: number | null,
      is_active?: boolean | null,
      is_processing?: boolean | null,
      recurring_id?: string | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      mute_all_emails?: boolean | null,
      next_payment_date?: string | null,
      payment_interval?: RecurringInterval | null,
      prev_payment_date?: string | null,
      recurring_description?: string | null,
      recurring_name?: string | null,
      reference?: string | null,
      remaining_payments?: number | null,
      status?: RecurringStatus | null,
      total_amount_per_payment?: number | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type ServiceFeeAmountQueryVariables = {
  merchant_uid: string,
  amount: number,
};

export type ServiceFeeAmountQuery = {
  serviceFeeAmount?:  {
    __typename: "ServiceFeeAmount",
    ach?:  {
      __typename: "ServiceFeeCalculation",
      adjusted_total?: number | null,
      fee?: number | null,
      fee_limit_reached?: boolean | null,
      total?: number | null,
    } | null,
    card?:  {
      __typename: "ServiceFeeCalculation",
      adjusted_total?: number | null,
      fee?: number | null,
      fee_limit_reached?: boolean | null,
      total?: number | null,
    } | null,
  } | null,
};

export type SettlementsQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type SettlementsQuery = {
  settlements?:  {
    __typename: "Settlements",
    items?:  Array< {
      __typename: "Settlement",
      currency?: string | null,
      gross_amount?: number | null,
      merchant_uid?: string | null,
      net_amount?: number | null,
      settlement_batch?: number | null,
      settlement_date?: string | null,
      status?: string | null,
      transaction_debit_count?: number | null,
      transaction_dispute_count?: number | null,
      transaction_reversal_count?: number | null,
      transfer_date?: string | null,
      total_adjustments?: number | null,
      total_fees?: number | null,
      updated_row_at?: string | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type SupportConversationQueryVariables = {
  merchant_uid: string,
  ticket_id: string,
};

export type SupportConversationQuery = {
  // Get the fresh desk conversation data associated with the passed in ticket ID
  supportConversation?:  {
    __typename: "SupportConversation",
    support_notes?:  Array< {
      __typename: "SupportNote",
      body?: string | null,
    } | null > | null,
    ticket_id: string,
  } | null,
};

export type TransactionsQueryVariables = {
  query?: SqlQuery | null,
  limit?: number | null,
  offset?: string | null,
  direction?: MoveDirection | null,
  offset_id?: string | null,
};

export type TransactionsQuery = {
  transactions?:  {
    __typename: "Transactions",
    items?:  Array< {
      __typename: "Transaction",
      account_code?: string | null,
      authorization_id?: string | null,
      currency?: string | null,
      device_id?: string | null,
      dispute_status?: DisputeStatus | null,
      failure_reasons?: Array< string | null > | null,
      fee_mode?: FeeMode | null,
      fees?: number | null,
      is_settled?: boolean | null,
      gross_amount?: number | null,
      merchant_uid?: string | null,
      metadata?: string | null,
      net_amount?: number | null,
      parent_id?: string | null,
      reference?: string | null,
      refund_voidable?: boolean | null,
      refunded_amount?: number | null,
      sale_id?: string | null,
      settlement_batch?: number | null,
      status?: TransactionStatus | null,
      timezone?: string | null,
      transaction_date?: string | null,
      transaction_id?: string | null,
      transaction_type?: TransactionType | null,
      updated_row_at?: string | null,
    } | null > | null,
    total_row_count?: number | null,
  } | null,
};

export type UsersQueryVariables = {
  user_pool: UserPool,
  merchant_uid?: string | null,
};

export type UsersQuery = {
  users?:  Array< {
    __typename: "User",
    email?: string | null,
    full_name?: string | null,
    phone?: string | null,
    user_status?: string | null,
    username?: string | null,
  } | null > | null,
};

export type SubscribeCardPresentCompletionSubscriptionVariables = {
  transaction_id: string,
};

export type SubscribeCardPresentCompletionSubscription = {
  subscribeCardPresentCompletion?:  {
    __typename: "CardPresentPayment",
    amount?: number | null,
    card_brand?: string | null,
    created_at?: string | null,
    currency?: string | null,
    failure_reason?: Array< string | null > | null,
    last_four?: string | null,
    service_fee?: number | null,
    status?: TransactionStatus | null,
    transaction_id?: string | null,
  } | null,
};

export type SubscribeNotificationSubscriptionVariables = {
  merchant_uid: string,
  context_id?: NotificationContextId | null,
  primary_value?: string | null,
  secondary_value?: string | null,
  error?: string | null,
};

export type SubscribeNotificationSubscription = {
  subscribeNotification?:  {
    __typename: "Notification",
    context_id: NotificationContextId,
    error?: string | null,
    merchant_uid: string,
    primary_value?: string | null,
    secondary_value?: string | null,
  } | null,
};
