/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const subscribeCardPresentCompletion = /* GraphQL */ `subscription SubscribeCardPresentCompletion($transaction_id: String!) {
  subscribeCardPresentCompletion(transaction_id: $transaction_id) {
    amount
    card_brand
    created_at
    currency
    failure_reason
    last_four
    service_fee
    status
    transaction_id
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeCardPresentCompletionSubscriptionVariables,
  APITypes.SubscribeCardPresentCompletionSubscription
>;
export const subscribeNotification = /* GraphQL */ `subscription SubscribeNotification(
  $merchant_uid: ID!
  $context_id: NotificationContextId
  $primary_value: String
  $secondary_value: String
  $error: String
) {
  subscribeNotification(
    merchant_uid: $merchant_uid
    context_id: $context_id
    primary_value: $primary_value
    secondary_value: $secondary_value
    error: $error
  ) {
    context_id
    error
    merchant_uid
    primary_value
    secondary_value
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeNotificationSubscriptionVariables,
  APITypes.SubscribeNotificationSubscription
>;
