/* global FreshworksWidget */
import React, { useContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { PortalHead } from '@paytheory/components.common.portal_head';
import { NavigationDrawer } from '@paytheory/components.common.navigation_drawer';

import { AppContext } from '../../App';

// @ts-ignore
import { BooksHooks } from '@paytheory/pay-theory-ui';
import {AuthEventData} from "@aws-amplify/ui";

type menuItems = Array<{
    tag?: string;
    label: string;
    to: string;
    icon?: string;
    iconBrand?: string;
    badgeNumber?: number;
    isCategory?: boolean;
    subContent?: Array<{
        tag?: string;
        label: string;
        to: string;
        icon?: string;
        iconBrand?: string;
        badgeNumber?: number;
    }>;
}>

type AdminPortalProps = {
    generateMenu: () => menuItems;
    paged: BooksHooks.Context.Page.Paged;
    logout:  ((data?: (AuthEventData | undefined)) => void) | undefined
}

const AdminPortal = ({ generateMenu, paged, logout }: AdminPortalProps) => {
    const { merchant } = useContext(AppContext);

    const portalHeadButtons = [
        {
          type: "action",
          icon: "question-circle",
          tooltip: "Get Help",
          action: () => {
              // @ts-ignore
            FreshworksWidget('open');
          }
        },
        {
          type: "action",
          icon: "sign-out",
          tooltip: "Sign Out",
          action: logout || (() => {})
        }
      ];

    const pageMenu = useMemo(generateMenu, [generateMenu]) as menuItems;


    return (
        <BooksHooks.Context.Menu.Provider value={pageMenu}>
                    <BooksHooks.Context.Page.Provider value={paged}>
                        <header>
                            <PortalHead trailingButtons={portalHeadButtons} />
                        </header>
                        <nav>
                            <NavigationDrawer menuItems={pageMenu} listHead={merchant?.merchant_name!} />
                        </nav>
                        <main>
                            <Outlet />
                        </main>
                    </BooksHooks.Context.Page.Provider>
        </BooksHooks.Context.Menu.Provider>
    )
};

export default AdminPortal;
