import {
    ConjunctiveOperator,
    CreateReceiptEmailMutationVariables,
    CreateRefundMutationVariables,
    Operator,
    QueryPair,
    RefundReasonCode,
    ServiceFeeAmount,
    ServiceFeeAmountQueryVariables,
    SqlQuery,
    Transaction,
    Transactions,
    TransactionsQuery,
    TransactionsQueryVariables
} from "./API/types"
import {generateClient} from "aws-amplify/api";
import {GeneratedQuery, ListParams, ParsedResponse} from "./util";
import {createReceiptEmail, createRefund} from "./API/graphql/mutations";
import {errorText, serviceFeeAmount} from "./API/graphql/queries";


type FullListVariables = TransactionsQueryVariables & {
    payment_method_query?: QueryPair[],
    payor_query?: QueryPair[],
}

const listTransactions = `query listTransactions($query: SqlQuery, $offset_id: String, $offset: String, $limit: Int, $direction: MoveDirection, $payment_method_query: [QueryPair], $payor_query: [QueryPair]) {
  transactions(query: $query, offset_id: $offset_id, offset: $offset, limit: $limit, direction: $direction) {
                    items {
                        merchant_uid
                        transaction_id
                        transaction_date
                        status
                        settlement_batch
                        payment_method(query_list: $payment_method_query) {
                          card_brand
                          last_four
                          payment_type
                          address_line1
                          address_line2
                          country
                          region
                          city
                          postal_code
                          payor(query_list: $payor_query) {
                            full_name
                            email
                            phone
                            address_line1
                            address_line2
                            country
                            region
                            city
                            postal_code
                          }
                        }
                        reference
                        account_code
                        transaction_type
                        dispute_status
                        net_amount
                        gross_amount
                        fees
                        currency
                        failure_reasons
                        refunded_amount
                        parent_id
                      }
                      total_row_count
                    }
}` as GeneratedQuery<FullListVariables, TransactionsQuery>

export type ListTransactionsFilter = {
    transactionQuery: QueryPair[],
    paymentMethodQuery?: QueryPair[],
    payorQuery?: QueryPair[],
}

export const list = async (params: ListParams<Transaction, ListTransactionsFilter>): Promise<ParsedResponse<Transactions>>  => {
    const client = generateClient()
    let sort = [{key: 'transaction_date', direction: params.order}];
    const queryObject = {
        query_list: params.filter?.transactionQuery,
        sort_list: params.order ? sort : [],
    };
    const variables: FullListVariables = {
        query: queryObject,
        offset_id: params.offset?.transaction_id,
        offset: params.offset?.transaction_date,
        limit: params.limit,
        direction: params.direction,
        payment_method_query: params.filter?.paymentMethodQuery,
        payor_query: params.filter?.payorQuery,
    };
    try{
      let response = await client.graphql({
          query: listTransactions,
          variables: variables
      })
        if (response.errors) {
            return {
                data: null,
                errors: response.errors.map((e: any) => e.message)
            }
        }
        return {
            data: response.data.transactions!,
            errors: null
        }
    } catch (e) {
        console.log(e)
        return {
            data: null,
            errors: ['Error fetching transactions']
        }
    }
}

export const refund = (amount: string, reason: RefundReasonCode, description: string, refundEmail: string | null, transactionId: string) => {
    const client = generateClient()
    let amountInt = parseInt(amount.replace(/[^0-9]/g, ''));
    let refundVariables: CreateRefundMutationVariables = {
        amount: amountInt,
        refund_reason: {
            reason_code: reason,
            reason_details: description
        },
        refund_email: refundEmail,
        transaction_id: transactionId
    };
    return client.graphql({query: createRefund, variables: refundVariables})
};

const getTransactions = `query getTransactionAndRefunds($query: SqlQuery) {
                transactions(query: $query, limit: 100) {
                  items {
                    merchant_uid
                    transaction_id
                    transaction_date
                    status
                    settlement_batch
                    payment_method {
                          card_brand
                          last_four
                          payment_type
                          address_line1
                          address_line2
                          country
                          region
                          city
                          postal_code
                          payor {
                            full_name
                            email
                            phone
                            address_line1
                            address_line2
                            country
                            country
                            region
                            city
                            postal_code
                          }
                        }
                    reference
                    account_code
                    transaction_type
                    dispute_status
                    net_amount
                    gross_amount
                    fees
                    currency
                    recurring {
                        recurring_id
                    }
                    invoice {
                        invoice_id
                    }
                    failure_reasons
                    ach_return_details {
                        return_code
                        return_details
                        transfer_type
                    }
                    refunded_amount
                    refund_voidable
                    refund_reason {
                        reason_code
                        reason_details
                      }
                      parent_id
                  }
              }
            }` as GeneratedQuery<TransactionsQueryVariables, TransactionsQuery>;

export const get = async (transactionId: string): Promise<ParsedResponse<Transactions>> => {
    const client = generateClient()
    if (transactionId.includes(':')) {
        transactionId = transactionId.split(':')[0]
    }
    const query: SqlQuery = {
        query_list: [
            {
                key: "transaction_id",
                value: transactionId,
                operator: Operator.EQUAL,
                conjunctive_operator: ConjunctiveOperator.OR_NEXT
            },
            {
                key: "parent_id",
                value: transactionId,
                operator: Operator.EQUAL
            }
        ]
    };
    const variables: TransactionsQueryVariables = {
        query: query
    };
    try{
        let response = await client.graphql({
            query: getTransactions,
            variables: variables
        })
        if (response.errors) {
            return {
                data: null,
                errors: response.errors.map((e: any) => e.message)
            }
        }
        return {
            data: response.data.transactions!,
            errors: null
        }
    } catch (e) {
        console.log(e)
        return {
            data: null,
            errors: ['Error fetching transactions']
        }
    }
};

const getChartData = `query getChartData($query: SqlQuery, $limit: Int = 100, $offset_id: String, $offset: String) {
                transactions(query: $query, limit: $limit, offset_id: $offset_id, offset: $offset) {
                  items {
                    transaction_id
                    transaction_date
                    gross_amount
                    transaction_type
                  }
                  total_row_count
              }
            }` as GeneratedQuery<TransactionsQueryVariables, TransactionsQuery>;

export const chartData = async (params: ListParams<Transaction, ListTransactionsFilter>): Promise<ParsedResponse<Transactions>> => {
    const client = generateClient()
    let sort = [{key: 'transaction_date', direction: params.order}];
    const queryObject = {
        query_list: params.filter?.transactionQuery,
        sort_list: sort,
    };
    const variables: FullListVariables = {
        query: queryObject,
        offset_id: params.offset?.transaction_id,
        offset: params.offset?.transaction_date,
        limit: params.limit,
        direction: params.direction,
        payment_method_query: params.filter?.paymentMethodQuery,
        payor_query: params.filter?.payorQuery,
    };
    try{
        let response = await client.graphql({
            query: getChartData,
            variables: variables
        })
        if (response.errors) {
            return {
                data: null,
                errors: response.errors.map((e: any) => e.message)
            }
        }
        return {
            data: response.data.transactions!,
            errors: null
        }
    } catch (e) {
        console.log(e)
        return {
            data: null,
            errors: ['Error fetching chartData']
        }
    }
}

export const getInvoicePayments = async (invoiceId: string): Promise<ParsedResponse<Transactions>> => {
    const client = generateClient()

    const query = {
        query_list: [
            {
                key: "invoice_id",
                value: `%${invoiceId}%`,
                operator: Operator.LIKE
            }
        ]
    };
    const variables = {
        query: query
    };

    try{
        let response = await client.graphql({
            query: getTransactions,
            variables: variables
        })
        if (response.errors) {
            return {
                data: null,
                errors: response.errors.map((e: any) => e.message)
            }
        }
        return {
            data: response.data.transactions!,
            errors: null
        }
    } catch (e) {
        console.log(e)
        return {
            data: null,
            errors: ['Error fetching transactions']
        }
    }
};

export const calcFees = async (amount: number, merchantUid: string): Promise<ParsedResponse<ServiceFeeAmount>> => {
    const client = generateClient()
    const variables: ServiceFeeAmountQueryVariables = {
        amount: amount,
        merchant_uid: merchantUid
    };
    try{
        let response = await client.graphql({
            query: serviceFeeAmount,
            variables: variables
        })
        if (response.errors) {
            return {
                data: null,
                errors: response.errors.map((e: any) => e.message)
            }
        }
        return {
            data: response.data.serviceFeeAmount!,
            errors: null
        }
    } catch (e) {
        console.log(e)
        return {
            data: null,
            errors: ['Error fetching transactions']
        }
    }
}

export const sendReceipt = async (transactionId: string, email: string): Promise<ParsedResponse<boolean>> => {
    const client = generateClient()
    const variables: CreateReceiptEmailMutationVariables = {
        email,
        transaction_id: transactionId
    }
    try {
        const response = await client.graphql({query: createReceiptEmail, variables: variables})
        if (response.errors) {
            return {
                data: null,
                errors: response.errors.map((e: any) => e.message)
            }
        }
        return {
            data: response.data.createReceiptEmail!,
            errors: null
        }
    } catch (e) {
        console.log(e)
        return {
            data: null,
            errors: ['Error sending receipt']
        }
    }
}

export const getLocalizedError = async (error_codes: Array<string>, language_code: string = "en-US"): Promise<ParsedResponse<Array<string | null>>> => {
    const client = generateClient()
    const variables = {
        error_codes,
        language_code
    };
    try {
        const response = await client.graphql({query: errorText, variables: variables});
        if (response.errors) {
            return { data: null, errors: response.errors.map((e: any) => e.message) }
        }
        return { data: response.data.errorText!, errors: null }
    } catch (e) {
        console.log(e)
        return { data: null, errors: ['Unable to fetch error text'] }
    }
}