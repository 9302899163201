import {Operator, QueryPair, Settlement, Settlements, SettlementsQueryVariables} from "./API/types";
import {ListParams, ParsedResponse} from "./util";
import {generateClient} from "aws-amplify/api";
import {settlements} from "./API/graphql/queries";


export const list = async (params: ListParams<Settlement, QueryPair[]>): Promise<ParsedResponse<Settlements>> => {
    const client = generateClient()
    let sort = [{key: 'settlement_batch', direction: params.order}]
    const queryObject = {
        query_list: params.filter,
        sort_list: sort,
    }
    const variables: SettlementsQueryVariables = {
        query: queryObject,
        offset_id: params.offset?.settlement_batch?.toString(),
        offset: params.offset?.settlement_batch?.toString(),
        limit: params.limit,
        direction: params.direction
    }
    try {
        const response = await client.graphql({ query: settlements, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.settlements!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch settlements']}
    }
}

export const get = async (settlementBatch: string): Promise<ParsedResponse<Settlement>> => {
    const client = generateClient()
    const queryObject = {
        query_list: [
            {
                key: "settlement_batch",
                value: settlementBatch,
                operator: Operator.EQUAL
            }
        ],
        sort_list: [],
    }
    const variables = {
        query: queryObject
    }
    try {
        const response = await client.graphql({ query: settlements, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        if(response.data.settlements &&
            response.data.settlements.items &&
            response.data.settlements.items.length > 0) {
            return {data: response.data.settlements.items[0]!, errors: null}
        }
        return {data: null, errors: ['Unable to find settlement']}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch settlement']}
    }
}