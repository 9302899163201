import {GeneratedQuery, ParsedResponse} from "./util";
import {
    CreateMerchantOnboardingMutationVariables, CreateSupportNoteMutationVariables,
    DocumentInput,
    MerchantOnboarding,
    MerchantOnboardingInput,
    MerchantOnboardingQuery,
    MerchantOnboardingQueryVariables
} from "./API/types";
import {generateClient} from "aws-amplify/api";
import {createMerchantOnboarding, createSupportNote} from "./API/graphql/mutations";
import {GraphQLError} from "graphql/error";

const getMerchantOnboarding = `query GetOnboardingData($merchant_uid: ID!) {
  merchantOnboarding(merchant_uid: $merchant_uid) {
    needs_docs
    ticket_id
    user_email
    user_full_name
    merchant_uid
    bank {
      account_number
      account_type
      bank_code
      name
    }
    business {
      additional_underwriting_data {
        annual_ach_volume
        average_ach_transfer_amount
        average_card_transfer_amount
        business_description
        card_volume_distribution {
          card_present_percentage
          ecommerce_percentage
        }
        credit_check_allowed
        credit_check_ip_address
        credit_check_timestamp
        credit_check_user_agent
        merchant_agreement_accepted
        merchant_agreement_ip_address
        merchant_agreement_timestamp
        merchant_agreement_user_agent
        refund_policy
        statement_descriptor
        volume_distribution_by_business_type {
          business_to_business_volume_percentage
          business_to_consumer_volume_percentage
          other_volume_percentage
        }
      }
      underwriting_data {
        annual_card_volume
        business_address {
          city
          country
          line1
          line2
          postal_code
          region
        }
        business_name
        business_type
        doing_business_as
        has_accepted_credit_cards_previously
        incorporation_date
        max_transaction_amount
        mcc
        ownership_type
        phone
        tax_id
        url
      }
    }
    business_owners {
      address {
        city
        country
        line1
        line2
        postal_code
        region
      }
      dob
      email
      first_name
      last_name
      middle_initial
      percentage_ownership
      phone
      primary_owner
      tax_id
      title
      uid
    }
    is_locked
  }
}` as GeneratedQuery<MerchantOnboardingQueryVariables, MerchantOnboardingQuery>

export const get = async (merchant_uid: string):  Promise<ParsedResponse<MerchantOnboarding>> => {
    const client = generateClient()
    const variables: MerchantOnboardingQueryVariables = {
        merchant_uid
    }
    try {
        const response = await client.graphql({ query: getMerchantOnboarding, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.merchantOnboarding!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch merchant onboarding']}
    }
}

export const update = async (merchant_onboarding: MerchantOnboardingInput, is_form_completed: boolean, docs: DocumentInput[] | null): Promise<ParsedResponse<boolean>>  => {
    const client = generateClient()
    const variables: CreateMerchantOnboardingMutationVariables = {
        merchant_onboarding,
        is_form_completed,
        support_note: {
            support_docs: docs
        }
    }
    try {
        const response = await client.graphql({ query: createMerchantOnboarding, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: true, errors: null}
    } catch (e) {
        console.log(e)
        // @ts-ignore
        const errors = e?.errors?.map((err: GraphQLError) => err.message) || ['Unable to update merchant onboarding']
        return {data: null, errors}
    }
}

export const uploadOnboardingDocument = async (merchant_uid: string, ticket_id: number, docs: DocumentInput[]): Promise<ParsedResponse<boolean>> => {
    const client = generateClient()
    const variables: CreateSupportNoteMutationVariables = {
        merchant_uid,
        ticket_id: ticket_id.toString(),
        support_note: {
            support_docs: docs
        }
    }
    try {
        const response = await client.graphql({ query: createSupportNote, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: true, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to upload document']}
    }
}