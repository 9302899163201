import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import * as ROUTES from "./constants/routes";
// @ts-ignore
import {openSpinner} from "@paytheory/pay-theory-ui"
import {
    FeeModelDetail,
    Invoice,
    Merchant,
    MoveDirection,
    QueryPair,
    RecurringPayment,
    Settlement,
    SortDirection,
    Transaction
} from "./network/API/types";
import {PayTheoryColor} from "@paytheory/components.common.global_style";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "./settingsUtil";
import {AuthUser} from "@aws-amplify/auth";

const generateMenu = (badge: number) => () => {
    return [{
        tag: "payments",
        icon: "usd-square",
        label: "Payments Home",
        isCategory: true,
        to: ROUTES.PAYMENTS,
        subContent: [
            {
                to: ROUTES.ALL_TRANSACTIONS,
                tag: 'all-transactions',
                label: 'All Transactions',
            },
        ]
    },
        {
            to: ROUTES.SETTLEMENTS,
            tag: 'settlements',
            icon: 'exchange-alt',
            label: 'Settlements',
            isCategory: false
        },
        {
            to: ROUTES.INVOICES,
            tag: 'invoices',
            icon: 'file-invoice-dollar',
            label: 'Invoices',
            isCategory: false
        },
        {
            to: ROUTES.RECURRING,
            tag: 'recurring',
            icon: 'envelope-open-dollar',
            label: 'Recurring',
            isCategory: false
        },
        {
            to: ROUTES.DISPUTES,
            tag: "disputes",
            icon: "backward",
            label: "Disputes",
            isCategory: false,
            badgeNumber: badge
        },
        {
            to: ROUTES.VIRTUAL_TERMINAL,
            tag: "virtual-terminal",
            icon: "cash-register",
            label: "Virtual Terminal",
            isCategory: false
        },
        {
            to: ROUTES.PAYMENT_LINKS,
            tag: "payment-links",
            icon: "link",
            label: "Payment Links",
            isCategory: false
        },
        {
            to: ROUTES.MERCHANT_SETTINGS,
            tag: "settings",
            icon: "users-cog",
            label: "Settings",
            isCategory: false
        },
    ];
};

const prepRoute = (route: string, replacements: { key: string, value: any }[] = []) => {
    return replacements.reduce((prepped, item) => {
        const keyed = `:${item.key}`
        return prepped.replace(keyed, item.value)
    }, route);
};

export {
    generateMenu,
    prepRoute
};

type statusChipObject = {
    color: PayTheoryColor,
    textColor: PayTheoryColor,
    text: string
}

export const statusChip: { [key: string]: statusChipObject } = {
    settled: {
        color: "grey",
        textColor: "white",
        text: "Settled"
    },
    paid: {
        color: "grey",
        textColor: "white",
        text: "Paid"
    },
    active: {
        color: "mint",
        textColor: "black",
        text: "Active"
    },
    inactive: {
        color: "grey-2",
        textColor: "black",
        text: "Inactive"
    },
    overdue: {
        color: "raspberry",
        textColor: "white",
        text: "Overdue"
    },
    reversed: {
        color: "yellow",
        textColor: "black",
        text: "Refunded"
    },
    refunded: {
        color: "yellow",
        textColor: "black",
        text: "Refunded"
    },
    pending: {
        color: "grey-2",
        textColor: "black",
        text: "Pending"
    },
    succeeded: {
        color: "mint",
        textColor: "black",
        text: "Succeeded"
    },
    failed: {
        color: "raspberry",
        textColor: "white",
        text: "Failed"
    },
    partially_refunded: {
        color: "yellow",
        textColor: "black",
        text: "Partially Refunded"
    },
    partially_paid: {
        color: "yellow",
        textColor: "black",
        text: "Partially Paid"
    },
    expiring: {
        color: "yellow",
        textColor: "black",
        text: "Expiring Card"
    },
    expired: {
        color: "raspberry",
        textColor: "white",
        text: "Expired Card"
    },
    returned: {
        color: "yellow",
        textColor: "black",
        text: "Returned"
    },
    voided: {
        color: "yellow",
        textColor: "black",
        text: "Voided"
    }
};

export const chargebackChip: Record<"won" | "lost" | "pending" | "action_required", statusChipObject> = {
    won: {
        color: "mint",
        textColor: "black",
        text: "Won"
    },
    lost: {
        color: "raspberry",
        textColor: "white",
        text: "Lost"
    },
    pending: {
        color: "grey-2",
        textColor: "black",
        text: "Pending"
    },
    action_required: {
        color: "yellow",
        textColor: "black",
        text: "Action Required"
    }
};

export const formatFee = (value: number | null) => {
    const newValue = value?.toString().replace(/[^0-9-]/g, '');
    let amount = parseFloat(newValue ?? "") ?? 0;
    return amount ? ((amount / 100).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
};

export const brandClasses = {
    VISA: "pay-theory-card-visa",
    DISCOVER: "pay-theory-card-discover",
    MASTERCARD: "pay-theory-card-mastercard",
    AMERICAN_EXPRESS: "pay-theory-card-american-express",
    AMEX: "pay-theory-card-american-express",
    CASH: "pay-theory-cash-badge",
    ACH: "pay-theory-ach-badge"
};

export const formatBasisPoints = (bp: number) => {
    const originalAmount = 100000;
    const totalAmount = Math.round(originalAmount / (1 - bp / 10000));
    const fee = totalAmount - originalAmount;
    return ((fee / originalAmount) * 100).toFixed(2);
};

export const formatDateAbrevMonth = (date: string) => {
    const dated = new Date(date);
    const month = dated.getMonth();
    const day = dated.getDate();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[month]} ${day}`;
}

export const formatDate = (date: string | Date) => {
    const dated = new Date(date);
    const month = dated.getMonth();
    const day = dated.getDate();
    const year = dated.getFullYear();
    const currentYear = new Date().getFullYear();

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[month]} ${day}${currentYear !== year ? `, ${year}` : ''}`;
};

export const formatOptionalDateString = (date: string) => {
    if (!date) return '-';
    return formatDate(date);
};

export const formatFullDate = (stamp: string) => {
    const dated = new Date(stamp);
    const month = (dated.getMonth() + 1).toString().padStart(2, '0');
    const day = (dated.getDate()).toString().padStart(2, "0");
    const year = dated.getFullYear();
    const hour = (dated.getHours() % 12 || 12).toString().padStart(2, "0");
    const minute = (dated.getMinutes()).toString().padStart(2, "0");
    const amOrPm = dated.getHours() > 11 ? "PM" : "AM";
    return `${month}/${day}/${year} ${hour}:${minute} ${amOrPm}`;
};

export const formatCurrency = (amount: number | string, currency = "USD") => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });
    amount = typeof amount === "string" ? parseFloat(amount) : amount;
    return formatter.format(amount);
}

function arrayToCSV(objArray: {}[]): string {
    if (objArray.length > 0) {
        const array =
            typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
        let str =
            `${Object.keys(array[0])
                .join(",")}` + "\r\n";

        return array.reduce((str: string, next: any) => {
            str +=
                `${Object.keys(next)
                    .map((key) => `"${next[key]}"`)
                    .join(",")}` + "\r\n";
            return str;
        }, str) + "\r\n\r\n";
    }
    return ""
}

/* global Blob URL */
export const downloadCSV = (items: { title?: string, items: {}[] }[], fileName: string) => {
    let link = document.createElement("a");

    // Avoid scrolling to bottom
    link.style.top = "0";
    link.style.left = "0";
    link.style.position = "fixed";

    document.body.appendChild(link);
    let text = "";
    for (const item of items) {
        if (item.items.length > 0) {
            if (item.title) text += `${item.title}\r\n`;
            text += arrayToCSV(item.items);
        }
    }
    const data = new Blob([text], {type: "text/csv"});
    link.href = URL.createObjectURL(data);
    link.download = `${fileName}.csv`;
    link.onclick = (e) => {
        if (items.length === 0) e.preventDefault();
    };
    link.click();

    document.body.removeChild(link);
};

type TransactionCSV = {
    merchant_uid: string;
    transaction_id: string;
    transaction_date: string;
    status: string;
    settlement_batch: string;
    payment_type: string;
    card_brand: string;
    last_four: string;
    full_name: string;
    reference: string;
    phone: string;
    email: string;
    account_code: string;
    transaction_type: string;
    dispute_status: string;
    net_amount: string;
    gross_amount: string;
    refunded_amount: string;
    fees: string;
    currency: string;
    failure_reasons: string;
    refund_reason: string;
    billing_address_line1: string;
    billing_address_line2: string;
    billing_city: string;
    billing_region: string;
    billing_postal_code: string;
    billing_country: string;
    payor_address_line1: string;
    payor_address_line2: string;
    payor_city: string;
    payor_region: string;
    payor_postal_code: string;
    payor_country: string;
}

export const prepareTransactionsForCSV = (transactions: Transaction[]): TransactionCSV[] => {
    return transactions.map((transaction) => {
        const result: TransactionCSV = {
            merchant_uid: transaction.merchant_uid!,
            transaction_id: transaction.transaction_id!,
            transaction_date: formatFullDate(transaction.transaction_date!),
            status: transaction.status!,
            settlement_batch: transaction.settlement_batch?.toString() ?? "",
            payment_type: transaction.payment_method!.payment_type!,
            net_amount: typeof transaction.net_amount === "number" ? formatCurrency(transaction.net_amount / 100, transaction.currency ?? "USD") : "",
            gross_amount: typeof transaction.gross_amount === "number" ? formatCurrency(transaction.gross_amount / 100, transaction.currency ?? "USD") : "",
            fees: typeof transaction.fees === "number" ? formatCurrency(transaction.fees / 100, transaction.currency ?? "USD") : "",
            refunded_amount: typeof transaction.refunded_amount === "number" ? formatCurrency(transaction.refunded_amount / 100, transaction.currency ?? "USD") : "",
            currency: transaction.currency ?? "",
            card_brand: transaction.payment_method!.card_brand ?? "",
            last_four: transaction.payment_method?.last_four ?? "",
            full_name: transaction.payment_method?.payor?.full_name ?? "",
            reference: transaction.reference ?? "",
            phone: transaction.payment_method?.payor?.phone ?? "",
            email: transaction.payment_method?.payor?.email ?? "",
            account_code: transaction.account_code ?? "",
            transaction_type: transaction.transaction_type ?? "",
            dispute_status: transaction.dispute_status ?? "",
            failure_reasons: transaction.failure_reasons?.join(" | ") ?? "",
            refund_reason: `${transaction.refund_reason?.reason_code ?? ""}${transaction.refund_reason?.reason_details ? ` | ${transaction.refund_reason?.reason_details}` : ""}`,
            billing_address_line1: transaction.payment_method?.address_line1 ?? "",
            billing_address_line2: transaction.payment_method?.address_line2 ?? "",
            billing_city: transaction.payment_method?.city ?? "",
            billing_region: transaction.payment_method?.region ?? "",
            billing_postal_code: transaction.payment_method?.postal_code ?? "",
            billing_country: transaction.payment_method?.country ?? "",
            payor_address_line1: transaction.payment_method?.payor?.address_line1 ?? "",
            payor_address_line2: transaction.payment_method?.payor?.address_line2 ?? "",
            payor_city: transaction.payment_method?.payor?.city ?? "",
            payor_region: transaction.payment_method?.payor?.region ?? "",
            payor_postal_code: transaction.payment_method?.payor?.postal_code ?? "",
            payor_country: transaction.payment_method?.payor?.country ?? "",
        }
        return result;
    })
}

export type InvoiceCSV = {
    merchant_uid: string;
    invoice_id: string;
    invoice_date: string;
    due_by: string;
    status: string;
    amount: string;
    invoice_name: string;
    invoice_description: string;
    invoice_number: string;
    payor_name: string;
    payor_email: string;
    payor_address_line1: string;
    payor_address_line2: string;
    payor_city: string;
    payor_region: string;
    payor_postal_code: string;
    payor_country: string;
    account_code: string;
    reference: string;
}

export const prepareInvoicesForCSV = (transactions: Invoice[]): InvoiceCSV[] => {
    return transactions.map((invoice) => {
        const result: InvoiceCSV = {
            merchant_uid: invoice.merchant_uid!,
            invoice_id: invoice.invoice_id!,
            invoice_date: formatAWSDate(invoice.invoice_date!)!,
            due_by: formatAWSDate(invoice.due_by!) || "",
            status: invoice.status!,
            amount: typeof invoice.invoice_amount === "number" ? formatCurrency(invoice.invoice_amount / 100, invoice.currency ?? "USD") : "",
            invoice_name: invoice.invoice_name ?? "",
            invoice_description: invoice.invoice_description ?? "",
            invoice_number: invoice.merchant_invoice_number ?? "",
            payor_name: invoice.payor?.full_name ?? "",
            payor_email: invoice.payor?.email ?? "",
            payor_address_line1: invoice.payor?.address_line1 ?? "",
            payor_address_line2: invoice.payor?.address_line2 ?? "",
            payor_city: invoice.payor?.city ?? "",
            payor_region: invoice.payor?.region ?? "",
            payor_postal_code: invoice.payor?.postal_code ?? "",
            payor_country: invoice.payor?.country ?? "",
            account_code: invoice.account_code ?? "",
            reference: invoice.reference ?? ""
        }
        return result;
    })
}

type SettlementCSV = {
    merchant_uid: string;
    settlement_batch: string;
    settlement_date: string;
    status: string;
    net_amount: string;
    gross_amount: string;
    total_fees: string;
    total_adjustments: string;
    currency: string;
}

export const prepareSettlementsForCSV = (settlements: Settlement[]): SettlementCSV[] => {
    return settlements.map((settlement) => {
        const result: SettlementCSV = {
            merchant_uid: settlement.merchant_uid!,
            settlement_batch: settlement.settlement_batch!.toString(),
            settlement_date: formatFullDate(settlement.settlement_date!),
            status: settlement.status!,
            net_amount: typeof settlement.net_amount === "number" ? formatCurrency(settlement.net_amount / 100, settlement.currency ?? "USD") : "",
            gross_amount: typeof settlement.gross_amount === "number" ? formatCurrency(settlement.gross_amount / 100, settlement.currency ?? "USD") : "",
            total_fees: typeof settlement.total_fees === "number" ? formatCurrency(settlement.total_fees / 100, settlement.currency ?? "USD") : "",
            total_adjustments: typeof settlement.total_adjustments === "number" ? formatCurrency(settlement.total_adjustments / 100, settlement.currency ?? "USD") : "",
            currency: settlement.currency ?? ""
        }
        return result;
    })
}

export const convertArrayToGraphQLString = (array: any) => {
    return array.reduce((str: string, item: any, index: number) => {
        if (index === array.length - 1) {
            return str + `"${item}"]`;
        } else {
            return str + `"${item}",`;
        }
    }, "[");
};

export const findPaymentMethodLogo = (item: Transaction | RecurringPayment | null | undefined): keyof typeof brandClasses => {
    if (item?.payment_method?.payment_type === "ACH") {
        return "ACH";
    } else if (item?.payment_method?.payment_type === "CASH") {
        return "CASH";
    } else if (item?.payment_method?.card_brand) {
        return item.payment_method.card_brand.toUpperCase() === "AMEX" ? "AMERICAN_EXPRESS" : item.payment_method.card_brand.toUpperCase() as keyof typeof brandClasses;
    }
    return item?.payment_method?.card_brand as keyof typeof brandClasses;
};

export const compareState = (a: any, b: any) => {
    if (typeof a === 'object' && typeof b === 'object') {
        let result = false;
        Object.keys(a).forEach(key => {
            if (a[key] !== b[key]) {
                result = true;
            }
        });
        return result;
    } else {
        return a !== b;
    }
};

export const useDebounce = (value: any, action: (value: any) => void, delay: number) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                if (compareState(value, debouncedValue)) {
                    setDebouncedValue(value);
                    action(value)
                }
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value]
    );

    return debouncedValue;
};

export const useUserTimeout = (user: AuthUser | undefined, signOut: () => void) => {
    useEffect(() => {
        // Set timeout longer for sandbox
        const minutesTillTimeout = process.env.REACT_APP_STAGE !== 'paytheory' ? 60 : 10;
        const timeout = minutesTillTimeout * 60000;
        const actions = ['mousemove', 'scroll', 'keydown', 'click', 'mousedown'];
        const username: string | undefined = user?.username

        // Function to update users timestamp in local storage
        let updateTimestamp = () => {
            const timestamp = Date.now()
            if (username) {
                setLocalStorage(user?.username!)(`${timestamp}`)
            }
        }

        // Function to check if user has been inactive for longer than timeout
        let checkTimestamp = () => {
            if (!username) return
            const timestamp = getLocalStorage(username)()
            const parsedTimestamp = timestamp ? parseInt(timestamp) : 0
            if (!parsedTimestamp) return
            const now = Date.now()
            const diff = now - parsedTimestamp
            if (diff > timeout) {
                signOut()
                // After 20 seconds, remove timestamp from local storage
                setTimeout(() => {
                    removeLocalStorage(username)()
                }, 20000)
            }

        }

        // If no timestamp in local storage, set one
        if (username) {
            const timestamp = getLocalStorage(username)()
            if (!timestamp) {
                updateTimestamp()
            }
        }

        // Set interval to check timestamp
        let t = setInterval(checkTimestamp, 5000);

        actions.forEach((action) => {
            document.addEventListener(action, updateTimestamp, {
                capture: false,
                passive: true
            })
        })

        return () => {
            actions.forEach((action) => {
                document.removeEventListener(action, updateTimestamp)
            })
            clearInterval(t)
        };
    }, [user, signOut]);
}

export const validDate = (date: string) => {
    return date.match(/^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|[12][0-9]|3[01]|[1-9])\/[0-9]{4}$/) ? true : false;
};

export const validEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? true : false;
};


export const formatAWSDate = (date: string | null): string | null => {
    if (!date) return null;
    const dateArray = date.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
};

export const convertToAwsDate = (date: string | null): string | null => {
    if (!date) return null;
    const dateArray = date.split('/');
    return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
};

const addMonths = (date: Date, months: number) => {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
};

const addDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + +days);
    return date;
};

const addYears = (date: Date, years: number) => {
    var d = date.getDate();
    date.setFullYear(date.getFullYear() + +years);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
};

let dateOffsetValues: {
    [key: string]: {
        function: (date: Date, value: number) => Date,
        value: number
    }
} = {
    '1D': {
        function: addDays,
        value: 1
    },
    '1W': {
        function: addDays,
        value: 7
    },
    '1M': {
        function: addMonths,
        value: 1
    },
    '3M': {
        function: addMonths,
        value: 3
    },
    '1Y': {
        function: addYears,
        value: 1
    }
};

export const formatDateToISO = (value: string, endOfDay: boolean) => {
    let date = new Date(value).toISOString()
    let dateString = date.split('T')[0]
    let ending = endOfDay ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
    return dateString + ending;
};


export const findDateOffset = (value: string, endOfDay: boolean) => {
    let valueObject = dateOffsetValues[value];
    let date = valueObject.function(new Date(), -valueObject.value);
    let dateString = date.toISOString().split('T')[0];
    let ending = endOfDay ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
    return dateString + ending;
};

export const capitalize = (string: string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertAmount = (amount: number, currency: string = "USD") => {
    const fraction = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });
    const whole = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const adjustedAmount = amount / 100;
    return adjustedAmount % 1 === 0 ? whole.format(adjustedAmount) : fraction.format(adjustedAmount);
};

export type NormalizedDispute = {
    // merchant: string,
    transactionId: string,
    disputeId: string,
    status: string,
    reason: string,
    amount: number,
    chargebackDate: string,
    updatedDate: string,
    expirationDate: string,
    accountCode: string,
    // descriptor: string,
    lastFour: string,
    cardBrand: string,
    fullName: string,
    reasonMessage: string,
    settlementBatch: string,
    transactionDate: string,
    phone: string,
    email: string,
    evidenceLastSendDate: string | number,
    settlementWithdrawalBatch: string,
    settlementDepositBatch: string,
}

export const expirationStatus = (exp: string | null): "EXPIRED" | "EXPIRING" | null => {
    // If no expiration date, return null
    if (!exp) return null;
    let now = new Date();
    let year = parseInt(`${now.getFullYear()}`.substring(2), 10);
    let month = now.getMonth() + 1;
    let expMonth = parseInt(exp.substring(0, 2), 10);
    let expYear = parseInt(exp.substring(2), 10);
    // Check to see if it meets expired criteria
    if (expYear < year) {
        return 'EXPIRED';
    } else if (expYear === year && expMonth < month) {
        return 'EXPIRED';
    }
    // Calculate the month differential
    let monthDiff = expMonth - month;
    let monthDiffPerYear = (expYear - year) * 12;
    let monthDiffTotal = monthDiff + monthDiffPerYear;
    if (monthDiffTotal < 2) {
        return 'EXPIRING';
    }
    // Else return null for exp status
    return null;
};

export const isPastDue = (date: string) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dueDate = new Date(date);
    return dueDate < today;
};

export const findInvoiceStatus = (invoice: any) => {
    if (invoice.status.toLowerCase() === "paid") {
        return "paid";
    } else if (invoice.dueBy && isPastDue(formatAWSDate(invoice.dueBy) as string)) {
        return "overdue";
    } else if (invoice.status.toLowerCase() === "partially_paid") {
        return "partially_paid";
    } else {
        return "active";
    }
};

export const findRecurringStatus = (recurring: RecurringPayment) => {
    const status = recurring?.status?.toLowerCase()
    const expStatus = expirationStatus(recurring?.payment_method?.exp_date ?? "");
    if (!recurring.is_active) {
        return "inactive";
    }
    if (expStatus) {
        return expStatus.toLowerCase();
    } else if (status === "success") {
        return "active";
    } else if (status === "instrument_failure" || status === "system_failure") {
        return "failed";
    } else {
        return "inactive";
    }
};

export const resultsPerPageOptions = [
    {
        value: 10,
        label: '10'
    },
    {
        value: 25,
        label: '25'
    },
    {
        value: 50,
        label: '50'
    },
    {
        value: 100,
        label: '100'
    }
];

export type paginationAction = 'FIRST' | 'BACK' | 'FORWARD' | 'LAST';
type FilterType = Record<string | number, any> | string | QueryPair | QueryPair[] | null;
export type paginationFunctionType<T, F extends FilterType > = (message: string | null,
                                         order: SortDirection,
                                         offset: T | null,
                                         limit: number,
                                         filter: F,
                                         direction: MoveDirection,
                                         flip: boolean) => any;

export const onPagination = <T, F extends FilterType>(func: paginationFunctionType<T, F>,
                                      limit: number,
                                      filter: F,
                                      totalPages: number,
                                      totalResults: number,
                                      resultsArray: T[],
                                      page: number,
                                      setPage: Dispatch<SetStateAction<any>>) => (action: paginationAction) => {
    openSpinner();
    let newPage = 0;
    // Calculate new page
    switch (action) {
        case 'FIRST':
            newPage = 1
            break;
        case 'BACK':
            newPage = page - 1
            break;
        case 'FORWARD':
            newPage = page + 1
            break;
        case 'LAST':
            newPage = totalPages
            break;
        default:
            newPage = page
    }
    setPage(newPage)
    //Perform the action based on the new page
    if (newPage === 1) {
        func(null, SortDirection.DESC, null, limit, filter, MoveDirection.FORWARD, false)
    } else if (newPage === totalPages) {
        let newLimit = totalResults % limit
        newLimit = newLimit === 0 ? limit : newLimit
        func(null, SortDirection.ASC, null, newLimit, filter, MoveDirection.FORWARD, true)
    } else if (action === 'FORWARD') {
        const offset = resultsArray.slice(-1)[0]
        func(null, SortDirection.DESC, offset, limit, filter, MoveDirection.FORWARD, false)
    } else if (action === 'BACK') {
        const offset = resultsArray[0]
        func(null, SortDirection.DESC, offset, limit, filter, MoveDirection.BACKWARD, false)
    }
};

export const getTodayFormatted = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
};

export const parseError = (error: { errors: string | any[]; }) => {
    if (error?.errors?.length > 0) {
        return error.errors[0].message;
    } else {
        return null;
    }
};

export const validUrl = (str: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const safeParseInt = (value: string | null | number): number | null => {
    if (typeof value === "number" || value === null) return value
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? null : parsed;
}

export const hasBothFeeModes = (merchant: Merchant | null) => {
    return !!merchant?.fee_model?.service_fee && !!merchant?.fee_model?.merchant_fee
}

export const feeModelString = (feeModel: FeeModelDetail): { card: string, ach: string } => {
    const cardString = convertBasisAndFixed(feeModel?.card_basis, feeModel?.card_fixed);
    const achString = convertBasisAndFixed(feeModel?.ach_basis, feeModel?.ach_fixed);
    return {
        card: cardString,
        ach: achString
    }
}

const convertBasisAndFixed = (basis: number | undefined | null, fixed: number | undefined | null): string => {
    const basisString = basis ? `${basis / 100}%` : null;
    const fixedString = fixed ? `$${formatFee(fixed)}` : null;
    if (basisString && fixedString) {
        return `${basisString} + ${fixedString}`;
    } else if (basisString) {
        return basisString;
    } else if (fixedString) {
        return fixedString;
    } else {
        return "N/A";
    }
}
export const convertEmptyStringsToNull = (inputObject: object): object => {
    const copy = JSON.parse(JSON.stringify(inputObject))
    Object.keys(copy).forEach((key) => {
        if (copy[key] === "") {
            copy[key] = null;
        } else if (copy[key] instanceof Object) {
            copy[key] = convertEmptyStringsToNull(copy[key]);
        }
    })
    return copy;
}
