import {
    CreateOneTimePaymentMutationVariables,
    OneTimePayment,
    Operator,
    QueryPair,
    RecurringPayment,
    RecurringPayments,
    RecurringPaymentsQuery,
    RecurringPaymentsQueryVariables,
    SqlQuery,
    UpdateRecurringPaymentMutation,
    UpdateRecurringPaymentMutationVariables
} from "./API/types";
import {GeneratedQuery, ListParams, ParsedResponse} from "./util";
import {generateClient} from "aws-amplify/api";
import {cancelRecurringPayment, createOneTimePayment} from "./API/graphql/mutations";


const listRecurringPayments = `query ListRecurringPayments($direction: MoveDirection, $limit: Int, $offset: String, $offset_id: String, $query: SqlQuery, $payment_method_query: [QueryPair], $payor_query: [QueryPair]) {
  recurringPayments(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      amount_per_payment
      account_code
      created_date
      recurring_description
      fee_mode
      fee_per_payment
      is_active
      prev_payment_date
      merchant_uid
      next_payment_date
      payment_interval
      payment_method(query_list: $payment_method_query) {
        card_brand
        exp_date
        last_four
        payment_type
        is_active
        payment_method_id
        payor {
          email
          full_name
          phone
        }
      }
      payor(query_list: $payor_query) {
        email
        full_name
        phone
        payor_id
       }
      recurring_name
      recurring_id
      reference
      remaining_payments
      status
      total_amount_per_payment
    }
    total_row_count
  }
}
` as GeneratedQuery<ExpandedRecurringPaymentsVariables, RecurringPaymentsQuery>;

export type listRecurringFilterType = {
    recurringQuery: QueryPair[],
    paymentMethodQuery: QueryPair[],
    payorQuery: QueryPair[],
}

export type ExpandedRecurringPaymentsVariables =  RecurringPaymentsQueryVariables & {
    payment_method_query?: QueryPair[],
    payor_query?: QueryPair[],
}

export const list = async (params: ListParams<RecurringPayment, listRecurringFilterType>): Promise<ParsedResponse<RecurringPayments>> => {
    const client = generateClient()
    let sort = [{key: 'created_date', direction: params.order}]
    const queryObject: SqlQuery = {
        query_list: params.filter?.recurringQuery,
        sort_list: sort,
    }
    const variables: ExpandedRecurringPaymentsVariables = {
        query: queryObject,
        offset_id: params.offset?.recurring_id,
        offset: params.offset?.created_date,
        limit: params.limit,
        direction: params.direction,
        payor_query: params.filter?.payorQuery,
        payment_method_query: params.filter?.paymentMethodQuery,
    }
    try {
        const response = await client.graphql({ query: listRecurringPayments, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.recurringPayments!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch recurring payments']}
    }
}

const getRecurringPayment = `query GetRecurringPayment($query: SqlQuery) {
  recurringPayments(query: $query) {
    items {
      amount_per_payment
      created_date
      recurring_description
      fee_mode
      fee_per_payment
      is_active
      prev_payment_date
      merchant_uid
      next_payment_date
      payment_interval
      payment_method {
        card_brand
        exp_date
        last_four
        payment_type
        payment_method_id
        is_active
        payor {
          email
          full_name
          phone
          payor_id
        }
      }
      payor {
        email
        full_name
        phone
        payor_id
       }
      recurring_name
      recurring_id
      remaining_payments
      status
      total_amount_per_payment
    }
    total_row_count
  }
}
` as GeneratedQuery<RecurringPaymentsQueryVariables, RecurringPaymentsQuery>

export const get = async (id: string): Promise<ParsedResponse<RecurringPayment>> => {
    const queryObject = {
        query_list: [
            {
                key: "recurring_id",
                value: id,
                operator: Operator.EQUAL
            }
        ],
        sort_list: [],
    }
    const variables: RecurringPaymentsQueryVariables = {
        query: queryObject
    }
    try {
        const response = await generateClient().graphql({ query: getRecurringPayment, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        if( response.data.recurringPayments!.items &&
            response.data.recurringPayments?.items.length &&
            response.data.recurringPayments.items.length > 0) {
            return {data: response.data.recurringPayments.items[0]!, errors: null}
        }
        return {data: null, errors: ['Recurring payment not found']}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to fetch recurring payment']}
    }
}


export const updateRecurringPayment = `mutation UpdateRecurring($input: UpdateRecurringPaymentInput!) {
  updateRecurringPayment(input: $input) {
      amount_per_payment
      account_code
      created_date
      recurring_description
      fee_mode
      fee_per_payment
      is_active
      prev_payment_date
      merchant_uid
      next_payment_date
      payment_interval
      payment_method {
        card_brand
        exp_date
        last_four
        payment_type
        is_active
        payment_method_id
        payor {
          email
          full_name
          phone
          payor_id
        }
      }
      payor {
        email
        full_name
        phone
        payor_id
       }
      recurring_name
      recurring_id
      reference
      remaining_payments
      status
      total_amount_per_payment
      }
}` as GeneratedQuery<UpdateRecurringPaymentMutationVariables, UpdateRecurringPaymentMutation>;

export const update = async (paymentMethod: string, recurringId: string): Promise<ParsedResponse<RecurringPayment>> => {
    const client = generateClient()
    const variables: UpdateRecurringPaymentMutationVariables = {
        input: {
            payment_method_id: paymentMethod,
            recurring_id: recurringId
        }
    }
    try {
        const response = await client.graphql({ query: updateRecurringPayment, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.updateRecurringPayment!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to update recurring payment']}
    }
}

export const cancel = async (id: string): Promise<ParsedResponse<boolean>> => {
    const client = generateClient()
    const variables = {
        recurring_id: id,
    }
    try {
        const response = await client.graphql({ query: cancelRecurringPayment, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.cancelRecurringPayment!, errors: null}
    } catch (e) {
        console.log(e)
        return {data: null, errors: ['Unable to cancel recurring payment']}
    }
}

export const payOff = async (variables: CreateOneTimePaymentMutationVariables): Promise<ParsedResponse<OneTimePayment>> => {
    const client = generateClient()
    try {
        const response =  await client.graphql({ query: createOneTimePayment, variables: variables })
        if(response.errors) {
            return {data: null, errors: response.errors.map((err) => err.message)}
        }
        return {data: response.data.createOneTimePayment!, errors: null}
    } catch (e) {
        console.log(e)
        return Promise.resolve({data: null, errors: ['Unable to create one time payment']})

    }
}